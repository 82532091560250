import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.isSmallModifier
 * @returns {JSX.Element} Component.
 */
export const StatsSectionHeading = ({ children, isSmallModifier }) => (
    <>
        {children && (
            <h2
                className={`statspro-container__heading ${
                    isSmallModifier ? 'statspro-container__heading--small' : ''
                }`}
            >
                {children}
            </h2>
        )}
    </>
);

StatsSectionHeading.defaultProps = {
    name: 'StatsSectionHeading'
};

StatsSectionHeading.propTypes = {
    children: PropTypes.any,
    isSmallModifier: PropTypes.bool
};
