import React from 'react';
import PropTypes from 'prop-types';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { TeamWatermarkBackground } from 'common/react/components/TeamWatermarkBackground';

/**
 * Renders the team header section.
 *
 * @param {object} props    - The props.
 * @returns {React.JSX.Element}   - The formatted team header section.
 */
export const TeamAnnouncementsHeader = (props) => {
    return (
        <div
            className={`team-announcements__header ${PULSE.app.common.team.getTeamAbbr(
                `${props?.clubId}`
            )}`}
        >
            <TeamIcon
                teamAbbreviation={`${PULSE.app.common.team.getTeamAbbr(
                    `${props?.clubId}`
                )}`}
                className="team-announcements__header-club-logo"
                theme="light"
            />
            <h3 className="team-announcements__header-title">{props.name}</h3>
            {props?.clubId ? (
                <TeamWatermarkBackground
                    teamAbbreviation={`${PULSE.app.common.team.getTeamAbbr(
                        `${props?.clubId}`
                    )}`}
                    className="team-announcements__header-club-watermark"
                    side="right"
                    type="colour"
                />
            ) : null}
        </div>
    );
};

TeamAnnouncementsHeader.propTypes = {
    clubId: PropTypes.string,
    name: PropTypes.string
};
