import React from 'react';
import PropTypes from 'prop-types';

export const ImageElement = ({
    url,
    title = '',
    cssClass = '',
    lazyload = 'lazy',
    objectFit = false
}) => (
    <img
        src={url}
        alt={title}
        className={`img ${cssClass} ${
            objectFit ? 'object-fit-cover-picture__img' : ''
        }`}
        loading={lazyload}
    />
);

ImageElement.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    cssClass: PropTypes.string,
    lazyload: PropTypes.string,
    objectFit: PropTypes.bool
};
