import { useGetRegionsQuery } from 'common/store/modules/api/aflm-content-api';

export const oneYearFromNow = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
};

/**
 * Create a new Date instance from now and add the specified number of days to it
 *
 * @param {number} days - The number of days to go forward by
 * @returns {Date} - The date X days in the future
 */
export const daysFromNow = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
};

/**
 * Fetch regions data from API and sort into categories
 *
 * @returns {Array} An array with two elements - the first being an array of sorted Australia regions objects,
 * and the second being an array of sorted other regions objects
 */
export function useSortedRegions() {
    const { data = [] } = useGetRegionsQuery();

    const australiaRegions = data.filter(
        (region) => region.timezone.split('/')[0] === 'Australia'
    );

    const otherRegions = data.filter(
        (region) => region.timezone.split('/')[0] !== 'Australia'
    );

    return [
        australiaRegions.sort((a, b) => a.name.localeCompare(b.name)),
        otherRegions.sort((a, b) => a.name.localeCompare(b.name))
    ];
}
