import React from 'react';
import PropTypes from 'prop-types';
import { PlayerPhoto } from 'widgets/brownlow-tracker/js/components/PlayerPhoto';

/**
 * Renders field player chips with an optional photo
 *
 * @param {object} props - The props
 * @param {object} props.player - The player object
 * @param {string} props.homeOrAway - Either 'home' or 'away'
 * @param {string} props.teamAbbr - The players team abbreviation
 * @param {boolean} props.showPhoto - Should we render a player photo?
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsFieldPlayer = ({
    player,
    homeOrAway,
    teamAbbr = '',
    showPhoto
}) => {
    return (
        <>
            {showPhoto ? (
                <PlayerPhoto
                    className={'team-announcements-field__player-headshot'}
                    photoUrl={player.photoURL}
                    alt={`${player?.player?.player?.playerName?.givenName} ${player?.player?.player?.playerName?.surname}`}
                />
            ) : null}
            <div className="team-announcements-field__player-tag-badge">
                <span
                    className={`team-announcements-field__player-tag-badge-number team-lineups__position-meta-label--${homeOrAway} ${teamAbbr.toLowerCase()}`}
                >
                    {player?.jumperNumber}
                </span>
                <span className="team-announcements-field__player-tag-badge-name">
                    {player?.player?.player?.playerName?.givenName.charAt(0)}
                    {'. '}
                    {player?.player?.player?.playerName?.surname}
                </span>
            </div>
        </>
    );
};

TeamAnnouncementsFieldPlayer.propTypes = {
    player: PropTypes.object,
    homeOrAway: PropTypes.oneOf(['home', 'away']),
    teamAbbr: PropTypes.string,
    showPhoto: PropTypes.bool
};
