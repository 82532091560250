import React from 'react';
import PropTypes from 'prop-types';

import { SelectDesktop } from './SelectDesktop';
import { SelectMobile } from './SelectMobile';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param {any[]} props.items - Items.
 * @param {Function} [props.itemToString] - Item to string.
 * @param {any} [props.initialSelectedItem] - Initial selected item.
 * @param {any} [props.activeItem] - Active item from state.
 * @param {Function} [props.onSelectedItemChange] - On change callback.
 * @returns {JSX.Element} Component.
 */
export const Select = (props) => (
    <>
        <div className="u-hide-until-desktop w-100">
            <SelectDesktop {...props} />
        </div>
        <div className="u-hide-from-desktop w-100">
            <SelectMobile {...props} />
        </div>
    </>
);

Select.propTypes = {
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    initialSelectedItem: PropTypes.any,
    activeItem: PropTypes.any,
    onSelectedItemChange: PropTypes.func
};
