import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query';

const { app } = PULSE;
const { API_RETRY_COUNT, STATUS_CODES } = app.common.CONSTANTS;

/**
 * @param {string} baseUrl - The base url to put in front of the requests,
 * should come from app.environment object
 * @returns {Function} - The RTK fetchBaseQuery wrapped in a retry
 */
export const accountHeaderWithRetryBQ = (baseUrl) =>
    retry(
        async (args, api, extraOptions) => {
            const result = await fetchBaseQuery({
                baseUrl,
                prepareHeaders: (headers) => {
                    headers.set(
                        app.accountHeader.label,
                        app.accountHeader.value
                    );
                    return headers;
                }
            })(args, api, extraOptions);

            // bail out of re-tries immediately if not found,
            // because we know successive re-retries would be redundant
            if (result.error?.status === STATUS_CODES.NOT_FOUND) {
                retry.fail(result.error);
            }

            return result;
        },
        {
            maxRetries: API_RETRY_COUNT
        }
    );
