import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    ClubBadgeCellRenderer

    Custom cell renderer for club cell using club name and logo
*/
const ClubBadgeCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value.teamName || !value.abbreviation) {
        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell--club')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const clubIcon = PULSE.app.templating.render(
        {
            cssClass: ``,
            providerId: value.providerId
        },
        'common.team-icon'
    );

    const isSorted =
        additionalProps.field.fieldName ===
        additionalProps.customTable.sortBy.fieldName
            ? true
            : false;

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--badge', {
                'custom-table__cell--sorted': isSorted
            })}
            {...additionalProps}
        >
            <span
                className="custom-table__club-icon"
                dangerouslySetInnerHTML={{ __html: clubIcon }}
            ></span>
        </DefaultCellRendererContainer>
    );
};

export default ClubBadgeCellRenderer;

ClubBadgeCellRenderer.propTypes = {
    className: PropTypes.any,
    value: PropTypes.any,
    teamName: PropTypes.any,
    abbreviation: PropTypes.any,
    providerId: PropTypes.any
};
