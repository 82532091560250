import { combineReducers } from 'redux';
import { leaderboardRootSaga } from './leaderboard/sagas';
import { leaderboardReducer } from './leaderboard/reducer';

const sagas = [
    leaderboardRootSaga
];

const rootReducer = combineReducers({
    leaderboard: leaderboardReducer
});

// @ts-expect-error
if (window.PULSE.app.redux) {
    // @ts-expect-error
    window.PULSE.app.redux.sagas.brownlowtracker = { root: sagas };
    // @ts-expect-error
    window.PULSE.app.redux.reducers.brownlowtracker = { root: rootReducer };
} else {
    // @ts-expect-error
    window.PULSE.app.redux = {
        reducers: {
            brownlowtracker: {
                root: rootReducer
            }
        },
        sagas: {
            brownlowtracker: {
                root: sagas
            }
        }
    };
}
