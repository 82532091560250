import { useMemo } from 'react';

/**
 * @param {Array} data the matches data array.
 * @returns {Array} matchItems
 */
export const useGroupMatches = (data) => {
    const matchItems = useMemo(() => {
        // Group results based on round
        if (data) {
            const matchesByRound = PULSE.app.common.arrayHelper.groupBy(
                data,
                (match) => {
                    return match.round.roundNumber;
                }
            );

            return matchesByRound;
        }

        return [];
    }, [data]);

    return matchItems;
};
