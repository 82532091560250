import { composeGetTranslation } from 'common/react/utils/translations';
import { STATS_CATEGORY } from './categories';

export const STATS_COLUMN_IDS = {
    AFL_FANTASY_POINTS: 'dreamTeamPoints',
    GOALS: 'goals',
    GOAL_ASSIST: 'goalAssists',
    GOAL_ACCURACY: 'goalAccuracy',
    SHOTS_AT_GOAL: 'shotsAtGoal',
    BEHINDS: 'behinds',
    DISPOSALS: 'disposals',
    MARKS: 'marks',
    CONTESTED_MARKS: 'contestedMarks',
    MARKS_INSIDE_50: 'marksInside50',
    MARKS_ON_LEAD: 'marksOnLead',
    INTERCEPT_MARKS: 'interceptMarks',
    TACKLES: 'tackles',
    TACKLES_INSIDE_50: 'tacklesInside50',
    PRESSURE_ACTS: 'pressureActs',
    DEFENSIVE_HALF_PRESSURE_ACTS: 'defHalfPressureActs',
    CONTESTED_DEFENSIVE_ONE_ON_ONES: 'contestDefOneOnOnes',
    CONTESTED_DEFENSIVE_LOSSES: 'contestDefLosses',
    CONTESTED_DEFENSIVE_LOSS_PERCENT: 'contestDefLossPercentage',
    CLEARANCES: 'totalClearances',
    KICKS: 'kicks',
    HANDBALLS: 'handballs',
    HIT_OUTS: 'hitouts',
    FREES_FOR: 'freesFor',
    FREES_AGAINST: 'freesAgainst',
    BOUNCES: 'bounces',
    ONE_PERCENTERS: 'onePercenters',
    TIME_ON_GROUND: 'timeOnGroundPercentage',
    INSIDE_50S: 'inside50s',
    DISPOSAL_EFFICIENCY: 'disposalEfficiency',
    CLANGERS: 'clangers',
    REBOUND_50S: 'rebound50s',
    EFFECTIVE_KICKS: 'effectiveKicks',
    KICK_EFFICIENCY: 'kickEfficiency',
    KICK_TO_HANDBALL_RATIO: 'kickToHandballRatio',
    EFFECTIVE_DISPOSALS: 'effectiveDisposals',
    SPOILS: 'spoils',
    CONTESTED_POSSESSIONS: 'contestedPossessions',
    UNCONTESTED_POSSESSIONS: 'uncontestedPossessions',
    INTERCEPT_POSSESSIONS: 'intercepts',
    TURNOVERS: 'turnovers',
    CONTESTED_POSSESSION_RATE: 'contestedPossessionRate',
    GROUND_BALL_GETS: 'groundBallGets',
    FWD_50_GROUND_BALL_GETS: 'f50GroundBallGets',
    CENTRE_CLEARANCES: 'centreClearances',
    STOPPAGE_CLEARANCES: 'stoppageClearances',
    TOTAL_CLEARANCES: 'totalClearances',
    HITOUTS_TO_ADVANTAGE: 'hitoutsToAdvantage',
    HITOUTS_TO_ADVANTAGE_PERCENTAGE: 'hitoutToAdvantageRate',
    HITOUT_WIN_PERCENTAGE: 'hitoutWinPercentage',
    RUCK_CONTESTS: 'ruckContests',
    SCORE_INVOLVEMENTS: 'scoreInvolvements',
    SCORE_LAUNCHES: 'scoreLaunches',
    GAMES_PLAYED: 'gamesPlayed',
    RATING_POINTS: 'ratingPoints',
    // NOTE: The constants below are not actually used as columns but they are
    // used for related stats.
    METRES_GAINED: 'metresGained',
    CENTRE_BOUNCE_ATTENDANCES: 'centreBounceAttendances',
    UNCONTESTED_MARKS: 'uncontestedMarks'
};

/**
 * @typedef {object} Stat
 * @property {string} id
 * @property {string} name
 * @property {string} abbreviation
 */

/**
 * @type {object<string, Stat>}
 */
export const STATS_COLUMNS = {};

// eslint-disable-next-line guard-for-in
for (let key in STATS_COLUMN_IDS) {
    const id = STATS_COLUMN_IDS[key];
    const getTranslation = composeGetTranslation(`label.statsLeaders.${id}.`);

    STATS_COLUMNS[id] = {
        id,
        name: getTranslation('name') ?? '',
        abbreviation: getTranslation('abbreviation') ?? ''
    };
}

Object.freeze(STATS_COLUMNS);

export const STATS_COLUMNS_FOR_CATEGORY = {
    [STATS_CATEGORY.KEY_STATS]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.AFL_FANTASY_POINTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES],
        STATS_COLUMNS[STATS_COLUMN_IDS.CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.HANDBALLS],
        STATS_COLUMNS[STATS_COLUMN_IDS.HIT_OUTS]
    ],
    [STATS_CATEGORY.GENERAL_PLAY]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.FREES_FOR],
        STATS_COLUMNS[STATS_COLUMN_IDS.FREES_AGAINST],
        STATS_COLUMNS[STATS_COLUMN_IDS.AFL_FANTASY_POINTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.BOUNCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.ONE_PERCENTERS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TIME_ON_GROUND]
    ],
    [STATS_CATEGORY.DISPOSALS]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.HANDBALLS],
        STATS_COLUMNS[STATS_COLUMN_IDS.INSIDE_50S],
        STATS_COLUMNS[STATS_COLUMN_IDS.DISPOSAL_EFFICIENCY],
        STATS_COLUMNS[STATS_COLUMN_IDS.CLANGERS],
        STATS_COLUMNS[STATS_COLUMN_IDS.REBOUND_50S],
        STATS_COLUMNS[STATS_COLUMN_IDS.EFFECTIVE_KICKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICK_EFFICIENCY],
        STATS_COLUMNS[STATS_COLUMN_IDS.KICK_TO_HANDBALL_RATIO],
        STATS_COLUMNS[STATS_COLUMN_IDS.EFFECTIVE_DISPOSALS]
    ],
    [STATS_CATEGORY.POSSESSIONS]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_POSSESSIONS],
        STATS_COLUMNS[STATS_COLUMN_IDS.UNCONTESTED_POSSESSIONS],
        STATS_COLUMNS[STATS_COLUMN_IDS.INTERCEPT_POSSESSIONS],
        STATS_COLUMNS[STATS_COLUMN_IDS.TURNOVERS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_POSSESSION_RATE],
        STATS_COLUMNS[STATS_COLUMN_IDS.GROUND_BALL_GETS],
        STATS_COLUMNS[STATS_COLUMN_IDS.FWD_50_GROUND_BALL_GETS]
    ],
    [STATS_CATEGORY.STOPPAGES]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.HIT_OUTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CENTRE_CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.STOPPAGE_CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.TOTAL_CLEARANCES],
        STATS_COLUMNS[STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE],
        STATS_COLUMNS[STATS_COLUMN_IDS.HITOUT_WIN_PERCENTAGE],
        STATS_COLUMNS[STATS_COLUMN_IDS.HITOUTS_TO_ADVANTAGE_PERCENTAGE],
        STATS_COLUMNS[STATS_COLUMN_IDS.RUCK_CONTESTS]
    ],
    [STATS_CATEGORY.MARKS]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS_INSIDE_50],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_MARKS],
        STATS_COLUMNS[STATS_COLUMN_IDS.MARKS_ON_LEAD],
        STATS_COLUMNS[STATS_COLUMN_IDS.INTERCEPT_MARKS]
    ],
    [STATS_CATEGORY.SCORING]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.GOALS],
        STATS_COLUMNS[STATS_COLUMN_IDS.BEHINDS],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOAL_ASSIST],
        STATS_COLUMNS[STATS_COLUMN_IDS.GOAL_ACCURACY],
        STATS_COLUMNS[STATS_COLUMN_IDS.SHOTS_AT_GOAL],
        STATS_COLUMNS[STATS_COLUMN_IDS.SCORE_INVOLVEMENTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.SCORE_LAUNCHES]
    ],
    [STATS_CATEGORY.DEFENCE]: [
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES],
        STATS_COLUMNS[STATS_COLUMN_IDS.TACKLES_INSIDE_50],
        STATS_COLUMNS[STATS_COLUMN_IDS.PRESSURE_ACTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.DEFENSIVE_HALF_PRESSURE_ACTS],
        STATS_COLUMNS[STATS_COLUMN_IDS.SPOILS],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_ONE_ON_ONES],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSSES],
        STATS_COLUMNS[STATS_COLUMN_IDS.CONTESTED_DEFENSIVE_LOSS_PERCENT]
    ]
};

Object.freeze(STATS_COLUMNS_FOR_CATEGORY);
