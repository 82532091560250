import React from 'react';
import PropType from 'prop-types';

export const FixtureWatermarks = ({ home, away }) => {
    const homeTeamAbbr = PULSE.app.common.team.getTeamAbbr(home.providerId);
    const awayTeamAbbr = PULSE.app.common.team.getTeamAbbr(away.providerId);

    return (
        <div className="fixtures__watermark-container">
            <div
                className={`fixtures__watermark fixtures__watermark--home ${ PULSE.app.common.template.getTeamThemeClass(homeTeamAbbr)}`}
                style={{
                    backgroundImage: `url('${PULSE.app.environment.watermarksPath}${homeTeamAbbr}-left-colour.png')`
                }}
            />
            <div
                className={`fixtures__watermark fixtures__watermark--away ${ PULSE.app.common.template.getTeamThemeClass(awayTeamAbbr)}`}
                style={{
                    backgroundImage: `url('${PULSE.app.environment.watermarksPath}${awayTeamAbbr}-right-colour.png')`
                }}
            />
        </div>
    );
};

FixtureWatermarks.propTypes = {
    home: PropType.object,
    away: PropType.object
};
