import produce from 'immer';

// eslint-disable-next-line no-unused-vars
import { CompetitionNavState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../types';
import { SET_NAV_ITEM, SET_FILTER_ITEM, SET_LIVE } from '../actions';
import { setNavItem } from './set-nav-item';
import { setFilterItem } from './set-filter-item';
import { setLive } from './set-live';

/**
 * @type {CompetitionNavState}
 */
const initialState = {
    competition: {
        id: '',
        providerId: ''
    },
    season: {
        id: '',
        providerId: ''
    },
    round: '',
    filters: {
        teams: [],
        venues: [],
        cities: []
    },
    otherMatches: {
        isActive: false
    },
    homeAway: 'all',
    bettingOdds: {
        isActive: true
    },
    live: {
        isActive: true
    },
    location: {
        timezone: ''
    }
};

/**
 * @param {CompetitionNavState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {CompetitionNavState} The updated Redux state.
 */
export const navReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_FILTER_ITEM:
                setFilterItem(draft, action.payload);
                break;
            case SET_LIVE:
                setLive(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
