import produce from 'immer';

import { PlayerStatsState } from '../../types';
import { Action } from '../../../../types';
import {
    SET_NAV_ITEM,
    SET_SORT,
    SET_DATA_TYPE,
    SET_CUSTOM_FIELDS,
    SET_MODAL,
    SET_TEMPORARY_FIELDS
} from '../actions';
import {
    setNavItem,
    setSort,
    setDataType
} from '../../shared/reducer';
import { setCustomFields } from './set-custom-fields';
import { setTemporaryFields } from './set-temporary-fields';
import { setModal } from './set-modal';

/**
 * @type {PlayerStatsState}
 */
const initialState = {
    nav: {
        stats: 'Basic',
        teams: 'Both'
    },
    sort: {
        columnId: 'player',
        sortDirection: 'ascending',
        force: false
    },
    dataType: {
        type: 'totals'
    },
    customFields: [],
    temporaryFields: [],
    modal: {
        isOpen: false
    }
};

/**
 * @param {PlayerStatsState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {PlayerStatsState} The updated Redux state.
 */
export const playerStatsReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_NAV_ITEM:
                setNavItem(draft, action.payload);
                break;
            case SET_SORT:
                setSort(draft, action.payload);
                break;
            case SET_DATA_TYPE:
                setDataType(draft, action.payload);
                break;
            case SET_CUSTOM_FIELDS:
                setCustomFields(draft, action.payload);
                break;
            case SET_TEMPORARY_FIELDS:
                setTemporaryFields(draft, action.payload);
                break;
            case SET_MODAL:
                setModal(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
