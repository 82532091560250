import { useSelector, useDispatch } from 'react-redux';

import { set, get } from 'common/store/modules/site-settings/init';
import { SITE_SETTINGS_CONFIG } from 'common/store/modules/site-settings/config';

/**
 * @typedef {object} SettingsReturn
 * @property {object} settings - An object containing the current settings
 * @property {Function} setSettings - A function to call when you want to update a setting
 */

/**
 * A custom hook to use when you want to access and/or change a website setting.
 * If you are working outside of react, have a look at
 * `site-settings-helpers.js` instead!
 *
 * @returns {SettingsReturn} An object containing the settings, and a function
 * to change settings
 *
 * @example
 *
 * import React from 'react';
 *
 * import { useSiteSettings } from 'common/react/hooks/use-site-settings';
 * import { lookupTranslation } from 'common/react/utils/translations';
 *
 * import { SwitchInput } from '../SwitchInput';
 *
 * export const SettingsDemoToggle = () => {
 *     const { siteSettings, setSiteSettings, settingsConfig } = useSiteSettings();
 *     const hideBettingSetting = settingsConfig.hideBetting;
 *
 *     return (
 *         <>
 *             <SwitchInput
 *                 id={hideBettingSetting.key}
 *                 label={lookupTranslation(hideBettingSetting.labelKey)}
 *                 isChecked={siteSettings[hideBettingSetting.key]}
 *                 onChange={(checked) => setSiteSettings({[hideBettingSetting.key]: checked})}
 *             />
 *
 *             <strong>Hide Betting:</strong> {siteSettings[hideBettingSetting.key].toString()}
 *         </>
 *     )
 * };
 */
export const useSiteSettings = () => {
    const dispatch = useDispatch();

    const siteSettings = useSelector(get);
    const setSiteSettings = (newSiteSettings) => dispatch(set(newSiteSettings));

    const settingsConfig = SITE_SETTINGS_CONFIG;

    return { siteSettings, setSiteSettings, settingsConfig };
};
