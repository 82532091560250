import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { SvgIcon } from '../../SvgIcon';

export const ResponsiveHomeAwayNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const filterApplied = nav.homeAway === 'all' ? true : false;

    const items = [
        {
            name: getTranslation('label.filters.allMatches'),
            value: 'all'
        },
        {
            name: getTranslation('label.filters.homeMatches'),
            value: 'home'
        },
        {
            name: getTranslation('label.filters.awayMatches'),
            value: 'away'
        }
    ];

    return (
        <div
            className={`competition-nav__season-select ${
                filterApplied
                    ? ''
                    : 'competition-nav__home-away-select--has-selected-item'
            }`}
        >
            <SvgIcon
                icon="location"
                className="competition-nav__home-away-select-icon"
            />

            <SvgIcon
                className="competition-nav__season-select-chevron"
                icon="chevron-down"
            />

            <Select
                items={items}
                itemToString={(item) => (item?.name ? item.name : '')}
                activeItem={items.find((i) => i.value === nav.homeAway)}
                initialSelectedItem={items.find(
                    (i) => i.value === nav.homeAway
                )}
                label={getTranslation('label.filters.homeAway')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'homeAway',
                            value: selectedItem.value
                        })
                    );
                }}
            />
        </div>
    );
};
