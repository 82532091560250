import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.teamAbbreviation
 * @param props.className
 * @param props.side
 * @param props.type
 * @returns {JSX.Element} Component.
 */
export const TeamWatermarkBackground = ({
    teamAbbreviation,
    className = '',
    side = 'right'
}) => (
    <div
        className={`stats-team-watermark-background ${PULSE.app.common.template.getTeamThemeClass(
            teamAbbreviation
        )} stats-team-watermark-background--${side} ${className}`}
    >
        <div className="stats-team-watermark-background__overlay"></div>
        <div
            className="stats-team-watermark-background__watermark"
            style={{
                backgroundImage: `url('${
                    PULSE.app.environment.watermarksPath
                }${teamAbbreviation.toLowerCase()}-${side}-dark.png')`
            }}
        />
    </div>
);

TeamWatermarkBackground.propTypes = {
    teamAbbreviation: PropTypes.string.isRequired,
    className: PropTypes.string,
    side: PropTypes.string,
    type: PropTypes.string
};
