import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../../setup-react';

// ===== Signals ============================================================ //

// Leaderboard
export const FETCH_LEADERBOARD_DATA = createSignalAction(
    'brownlow/leaderboard',
    'FETCH_LEADERBOARD_DATA'
);

// ===== Deltas ============================================================= //

// Leaderboard
export const SET_LEADERBOARD_DATA = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_LEADERBOARD_DATA'
);
export const setLeaderboardData = createActionCreator(SET_LEADERBOARD_DATA);

// Leaderboard request tracking
export const SET_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'SET_LEADERBOARD_DATA_REQUEST'
);
export const setLeaderboardDataRequest = createActionCreator(
    SET_LEADERBOARD_DATA_REQUEST
);

export const REMOVE_LEADERBOARD_DATA_REQUEST = createDeltaActionType(
    'brownlow/leaderboard',
    'REMOVE_LEADERBOARD_REQUEST'
);
export const removeLeaderboardDataRequest = createActionCreator(
    REMOVE_LEADERBOARD_DATA_REQUEST
);
