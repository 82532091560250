import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const PlayerPhoto = ({ className, photoUrl, alt, scale = '0.2' }) => {
    const [imageSrc, setImageSrc] = useState(
        PULSE.app.common.image.migratePlayerPhotoURL(photoUrl, scale)
    );
    const [imageClass, setImageClass] = useState(className);

    const onError = useCallback(() => {
        setImageSrc(
            `${PULSE.app.environment.cdn}i/elements/player-headshot-placeholder-light-v2.svg`
        );
        setImageClass(`${className} is-placeholder`);
    }, [className]);

    return (
        <img
            className={imageClass}
            src={imageSrc}
            alt={alt}
            width="1"
            height="1"
            onError={onError}
        />
    );
};

PlayerPhoto.propTypes = {
    className: PropTypes.string,
    photoUrl: PropTypes.string,
    alt: PropTypes.string.isRequired,
    scale: PropTypes.string
};
