import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TeamAnnouncementsHeader } from './TeamAnnouncementsHeader';
import { TeamAnnouncementsList } from './TeamAnnouncementsList';
import { TeamAnnouncementsLateChanges } from './TeamAnnouncementsLateChanges';
import { TeamAnnouncementsMilestones } from './TeamAnnouncementsMilestones';
import { TeamAnnouncementsField } from './TeamAnnouncementsField';
import { TeamAnnouncementsInsOuts } from './TeamAnnouncementsInsOuts';
import { TeamAnnouncementsFieldInsOuts } from './TeamAnnouncementsFieldInsOut';
import { TeamAnnouncementsFieldLateChanges } from './TeamAnnouncementsFieldLateChanges';
import { TeamAnnouncementsFieldMilestones } from './TeamAnnouncementsFieldMilestones';
import { sortPositionAreas } from '../util';
import { useWindowWidth } from 'common/react/hooks/use-window-resize';

class PlayersModel {
    constructor() {
        (this.backs = []),
            (this.halfBacks = []),
            (this.centres = []),
            (this.halfForwards = []),
            (this.forwards = []),
            (this.followers = []),
            (this.interchanges = []),
            (this.emergencies = []),
            (this.substitutes = []),
            this.lateChanges,
            (this.teamId = ''),
            (this.teamName = '');
    }
}

const fieldPositions = [
    'BPL',
    'FB',
    'BPR',
    'HBFL',
    'CHB',
    'HBFR',
    'WL',
    'C',
    'WR',
    'HFFL',
    'CHF',
    'HFFR',
    'FPL',
    'FF',
    'FPR'
];

/**
 * Renders the field and list content.
 *
 * @param {object} props - The props
 * @param {object} props.matchRosterData - The data for the match roster.
 * @param {object} props.viewMode - Whether to display the field or list.
 * @param {string} props.initialTeamDisplay - The preset team view display.
 * @param {Function} props.displayHome - Whether to display the home team's data.
 * @param {string} props.displayAway - Whether to display the away team's data.
 * @returns {React.JSX.Element} - The rendered component
 */
export const TeamAnnouncementsContent = ({
    viewMode,
    initialTeamDisplay,
    matchRosterData,
    displayHome,
    displayAway
}) => {
    const homeLateChanges = matchRosterData?.matchRoster?.homeTeam?.lateChanges;
    const awayLateChanges = matchRosterData?.matchRoster?.awayTeam?.lateChanges;

    const windowWidth = useWindowWidth();
    const maxWidowWidth = 640;

    const homeTeamModel = new PlayersModel();
    const awayTeamModel = new PlayersModel();

    const [teamDisplayState, setTeamDisplay] = useState(initialTeamDisplay);

    const matchStatus = matchRosterData?.match?.status;

    const lateChangesDisplayStatus =
        PULSE.app.common.CONSTANTS.DISPLAY_MATCH_STATUSES.includes(matchStatus);

    const homeMilestones = [];
    const awayMilestones = [];

    const playersData = matchRosterData?.teamPlayers;

    if (playersData) {
        homeMilestones.push(matchRosterData?.matchRoster?.homeTeam?.milestones);
        awayMilestones.push(matchRosterData?.matchRoster?.awayTeam?.milestones);

        homeTeamModel.teamName = matchRosterData?.match?.homeTeam?.name;
        awayTeamModel.teamName = matchRosterData?.match?.awayTeam?.name;
        homeTeamModel.teamId = PULSE.app.common.team.getTeamAbbr(
            matchRosterData?.match?.homeTeamId
        );
        awayTeamModel.teamId = PULSE.app.common.team.getTeamAbbr(
            matchRosterData?.match?.awayTeamId
        );

        homeTeamModel.lateChanges =
            matchRosterData?.matchRoster?.homeTeam?.lateChanges;
        awayTeamModel.lateChanges =
            matchRosterData?.matchRoster?.awayTeam?.lateChanges;

        homeTeamModel.fieldPlayers = playersData[0].players.filter((player) =>
            fieldPositions.includes(player.player.position)
        );
        awayTeamModel.fieldPlayers = playersData[1].players.filter((player) =>
            fieldPositions.includes(player.player.position)
        );

        // Group each teams players into position areas
        sortPositionAreas(
            playersData[0].players,
            'home',
            homeTeamModel,
            awayTeamModel
        );
        sortPositionAreas(
            playersData[1].players,
            'away',
            homeTeamModel,
            awayTeamModel
        );

        PULSE.app.common.objectHelper.clearEmptyObjectKeys(homeTeamModel);
        PULSE.app.common.objectHelper.clearEmptyObjectKeys(awayTeamModel);
    }

    const shouldShow = {
        milestones: {
            home: homeMilestones[0]?.length > 0,
            away: awayMilestones[0]?.length > 0
        }
    };

    // Conditions to determine whether home/away/both teams should be visible

    return viewMode === 'field' ? (
        <>
            <TeamAnnouncementsField
                homeData={homeTeamModel}
                awayData={awayTeamModel}
                matchStatus={matchStatus}
                initialTeamDisplay={initialTeamDisplay}
                teamDisplay={teamDisplayState}
                updateTeamDisplay={setTeamDisplay}
            />

            <div
                className={
                    teamDisplayState === 'both'
                        ? 'team-announcements-field__info-wrapper--both'
                        : 'team-announcements-field__info-wrapper'
                }
            >
                <TeamAnnouncementsFieldInsOuts
                    playersInHome={matchRosterData?.matchRoster?.homeTeam?.ins}
                    playersOutHome={
                        matchRosterData?.matchRoster?.homeTeam?.outs
                    }
                    playersInAway={matchRosterData?.matchRoster?.awayTeam?.ins}
                    playersOutAway={
                        matchRosterData?.matchRoster?.awayTeam?.outs
                    }
                    teamDisplay={teamDisplayState}
                />

                {lateChangesDisplayStatus ||
                ((shouldShow.milestones.home || shouldShow.milestones.away) &&
                    windowWidth > maxWidowWidth) ? (
                    <div className="team-announcements-field__footer-section-wrapper">
                        {shouldShow.milestones.home ||
                        shouldShow.milestones.away ? (
                            <TeamAnnouncementsFieldMilestones
                                matchStatus={matchStatus}
                                homeMilestones={homeMilestones[0]}
                                awayMilestones={awayMilestones[0]}
                                teamDisplay={teamDisplayState}
                            />
                        ) : null}

                        <TeamAnnouncementsFieldLateChanges
                            homeLateChanges={homeLateChanges}
                            awayLateChanges={awayLateChanges}
                            matchStatus={matchStatus}
                            teamDisplay={teamDisplayState}
                        />
                    </div>
                ) : null}
            </div>
        </>
    ) : (
        <>
            {displayHome ? (
                <div className="team-announcements__team team-announcements__team--home">
                    <TeamAnnouncementsHeader
                        name={matchRosterData?.match?.homeTeam?.name}
                        clubId={matchRosterData?.match?.homeTeam?.teamId}
                    />
                    <TeamAnnouncementsInsOuts
                        playersOut={
                            matchRosterData?.matchRoster?.homeTeam?.outs
                        }
                        playersIn={matchRosterData?.matchRoster?.homeTeam?.ins}
                    />
                    {lateChangesDisplayStatus || shouldShow.milestones.home ? (
                        <div className="team-announcements__late-changes-milestones">
                            {shouldShow.milestones.home ? (
                                <TeamAnnouncementsMilestones
                                    milestones={homeMilestones[0]}
                                />
                            ) : null}
                            <TeamAnnouncementsLateChanges
                                matchStatus={matchStatus}
                                lateChanges={homeLateChanges}
                            />
                        </div>
                    ) : null}
                    <div className="team-announcements__player-info-wrapper">
                        <TeamAnnouncementsList
                            players={homeTeamModel}
                            teamAbbr={PULSE.app.common.team.getTeamAbbr(
                                matchRosterData?.match?.homeTeam?.teamId
                            )}
                            teamLocation="home"
                            matchStatus={matchStatus}
                        />
                    </div>
                </div>
            ) : null}

            {displayAway ? (
                <div className="team-announcements__team team-announcements__team--away">
                    <TeamAnnouncementsHeader
                        name={matchRosterData?.match?.awayTeam?.name}
                        clubId={matchRosterData?.match?.awayTeam?.teamId}
                    />
                    <TeamAnnouncementsInsOuts
                        playersIn={matchRosterData?.matchRoster?.awayTeam?.ins}
                        playersOut={
                            matchRosterData?.matchRoster?.awayTeam?.outs
                        }
                    />
                    {lateChangesDisplayStatus || shouldShow.milestones.away ? (
                        <div className="team-announcements__late-changes-milestones">
                            {shouldShow.milestones.away ? (
                                <TeamAnnouncementsMilestones
                                    milestones={awayMilestones[0]}
                                />
                            ) : null}
                            <TeamAnnouncementsLateChanges
                                lateChanges={awayLateChanges}
                                matchStatus={matchStatus}
                            />
                        </div>
                    ) : null}
                    <div className="team-announcements__player-info-wrapper">
                        <TeamAnnouncementsList
                            players={awayTeamModel}
                            teamAbbr={PULSE.app.common.team.getTeamAbbr(
                                matchRosterData?.match?.awayTeam?.teamId
                            )}
                            teamLocation="away"
                            matchStatus={matchStatus}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};

TeamAnnouncementsContent.propTypes = {
    viewMode: PropTypes.oneOf(['field', 'list']),
    matchRosterData: PropTypes.object,
    initialTeamDisplay: PropTypes.oneOf(['both', 'home', 'away']),
    displayHome: PropTypes.bool,
    displayAway: PropTypes.bool
};
