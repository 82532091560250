/**
 * Use a class component to get access to lifecycle methods, otherwise use a functional component.
 * Should only contain rendering logic; business logic should be contained to Redux Sagas.
 */

import React from 'react';
import TableModal from './TableModal';
import PropTypes from 'prop-types';

class DefaultCustomTableModal extends React.Component {
    constructor(props) {
        super(props);

        // Set initial states
        this.state = {
            displayedFields: [],
            tableFieldGroups: {},
            localStorageFields: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.customTable && this.props.displayedCustomTableFields) {
            // If previous displayed fields don't equal new displayed fields
            if (
                JSON.stringify(prevProps.displayedCustomTableFields) !==
                JSON.stringify(this.props.displayedCustomTableFields)
            ) {
                this.setState({
                    displayedFields: [
                        ...this.filterInvisibleFields(
                            this.props.displayedCustomTableFields
                        )
                    ],
                    tableFieldGroups: { ...this.props.customTable.fieldGroups }
                });
            }
            this.updatePageElements();
        }
    }

    handleAddRemoveFieldClick = (field, tableName, groupName) => {
        const hasField =
            this.state.displayedFields.filter(
                (displayedField) => displayedField.fieldName === field.fieldName
            ).length > 0;
        let newFields;

        if (!hasField) {
            newFields = this.state.displayedFields;

            newFields.push(field);
        } else {
            newFields = this.state.displayedFields.filter((displayedField) => {
                return displayedField.fieldName !== field.fieldName;
            });
        }

        let groups = this.state.tableFieldGroups;
        groups[groupName].fields.forEach((groupField) => {
            if (groupField.fieldName === field.fieldName) {
                groupField.displayByDefault = !groupField.displayByDefault;
            }
        });

        this.setState({
            displayedFields: this.filterInvisibleFields(newFields),
            tableFieldGroups: groups
        });
    };

    handleRemoveAllFieldsClick = () => {
        const newGroups = this.state.tableFieldGroups;

        Object.keys(newGroups).forEach((key) => {
            if (!newGroups[key].isPersistent) {
                newGroups[key].fields.forEach((field, i) => {
                    if (!newGroups[key].fields[i].isPersistent) {
                        newGroups[key].fields[i].displayByDefault = false;
                    }
                });
            }
        });

        const newDisplayedFields = this.state.displayedFields.filter(
            (field) => {
                return field.isPersistent;
            }
        );

        this.setState({
            displayedFields: this.filterInvisibleFields(newDisplayedFields),
            tableFieldGroups: newGroups
        });
    };

    toggleModal = (tableName) => {
        this.props.toggleModal(tableName);

        if (!this.props.manageFieldsBarShowing) {
            this.props.toggleMangageFieldsBar(tableName);
        }

        this.setState({
            displayedFields: [
                ...this.filterInvisibleFields(
                    this.props.displayedCustomTableFields
                )
            ],
            tableFieldGroups: { ...this.props.customTable.fieldGroups }
        });
    };

    moveCard = (tableName, dragIndex, hoverIndex) => {
        // list of cards
        let newFields = this.state.displayedFields;

        // dragCard is card we are dragging
        let dragCard = newFields[dragIndex];

        // removing this dragCard from array
        newFields.splice(dragIndex, 1);

        // insert dragCard at hover position
        newFields.splice(hoverIndex, 0, dragCard);

        this.setState({
            displayedFields: this.filterInvisibleFields(newFields)
        });
    };

    filterInvisibleFields = (fields) => {
        return fields.filter((field) => {
            if (typeof field.visibiltyConditionFunc !== 'function') {
                return true;
            }

            return field.visibiltyConditionFunc(
                this.props.customTable.dataJson
            );
        });
    };

    // Gets translation if we dont mind the label being retured ie. Table Header that may not be translation
    getTranslation = (text) => {
        return PULSE.I18N.lookup(text);
    };

    getFallbackTranslation = (text, fallback, className) => {
        // Get the translation
        const translation = this.getTranslation(text);
        // Check if the translation = the text (ie. no translation found)
        let returnTranslation =
            translation === text ? this.getTranslation(fallback) : translation;

        if (returnTranslation.includes('|')) {
            const newClass = className ? className : '';
            let translationTextArray = returnTranslation.split('|');

            // Ensure where you are rendering this out you use dangerouslySetInnerHTML on the element else it will render span as string
            returnTranslation = `${translationTextArray[0]} <span class='${newClass}'>${translationTextArray[1]}</span>`;
        }

        return returnTranslation;
    };

    confirmFields = (tableName) => {
        localStorage.setItem(
            tableName,
            JSON.stringify(this.state.displayedFields)
        );

        this.toggleModal(tableName);

        this.props.updateDisplayedCustomTableFields(tableName, this.state);
    };

    setAccordionItem = (accordionName) => {
        if (!this.state.accordionItems) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.accordionItems = {};
        }

        let accordionItems = { ...this.state.accordionItems };

        if (!accordionItems[accordionName]) {
            accordionItems[accordionName] = {
                opened: false
            };
        }

        accordionItems[accordionName].opened =
            !accordionItems[accordionName].opened;

        this.setState({ accordionItems });
    };

    getAccordionItem = (accordionName) => {
        if (
            this.state.accordionItems &&
            this.state.accordionItems[accordionName]
        ) {
            return this.state.accordionItems[accordionName].opened;
        }

        return false;
    };

    setDescriptionShowing = (event, field) => {
        const elementHasClass = event.target.classList.contains('js-close-btn');
        const parentElement = event.target.closest('.js-close-btn');

        if (!elementHasClass && !parentElement) {
            this.setState({ descriptionShowing: field.fieldName });
        } else {
            this.setState({ descriptionShowing: '' });
        }
    };

    getDescriptionShowing = (field) => {
        return field.fieldName === this.state.descriptionShowing;
    };

    bodyClick = (event) => {
        const elementHasClass = event.target.classList.contains(
            'js-stats-item-description'
        );
        const parentElement = event.target.closest(
            '.js-stats-item-description'
        );

        if (!elementHasClass && !parentElement) {
            this.setState({ descriptionShowing: '' });
        } else {
            return;
        }
    };

    closeDescription = () => {
        this.setState({ descriptionShowing: '' });
    };

    updatePageElements = () => {
        document.body.classList.remove('custom-table-open');
        if (this.props.modalOpen) {
            document.body.classList.add('custom-table-open');
        }
    };

    render() {
        const editIcon = PULSE.app.templating.render(
            {
                cssClass: ``,
                name: 'edit'
            },
            'common.svg-icon'
        );

        const infoIcon = PULSE.app.templating.render(
            {
                cssClass: '',
                name: 'info'
            },
            'common.svg-icon'
        );

        if (
            !this.props.customTable ||
            (!this.props.customTable.fields && !this.props.customTable.failed)
        ) {
            return '';
        }

        return (
            <div className="custom-table__modal-button-container">
                <button
                    className="custom-table__modal-button button"
                    onClick={() => this.toggleModal(this.props.data.tablename)}
                >
                    <span className="custom-table__modal-button-text">
                        {this.getFallbackTranslation(
                            `label.customTable.manageStatsButton`,
                            'Manage Stats'
                        )}
                    </span>
                    <span
                        className="custom-table__modal-button-icon"
                        dangerouslySetInnerHTML={{ __html: editIcon }}
                    ></span>
                </button>
                <div className="custom-table__modal-button-description">
                    <p
                        className="custom-table__modal-button-description-text"
                        dangerouslySetInnerHTML={{
                            __html: this.getFallbackTranslation(
                                `label.customTable.savedDeviceText`,
                                `${this.props.customTable.config.infoText} | will only be saved on this device`,
                                'custom-table__modal-button-description-text-bold'
                            )
                        }}
                    ></p>
                    <span dangerouslySetInnerHTML={{ __html: infoIcon }}></span>
                </div>
                <div className="custom-default-table__modal">
                    <TableModal
                        key="table-modal"
                        state={this.state}
                        customTable={this.props.customTable}
                        tableName={this.props.data.tablename}
                        fields={this.props.customTable.fields}
                        displayedFields={this.props.displayedCustomTableFields}
                        toggleModal={this.toggleModal}
                        modalOpen={this.props.modalOpen}
                        handleAddRemoveFieldClick={
                            this.handleAddRemoveFieldClick
                        }
                        handleRemoveAllFieldsClick={
                            this.handleRemoveAllFieldsClick
                        }
                        moveCard={this.moveCard}
                        getTranslation={this.getTranslation}
                        getFallbackTranslation={this.getFallbackTranslation}
                        confirmFields={this.confirmFields}
                        setAccordionItem={this.setAccordionItem}
                        getAccordionItem={this.getAccordionItem}
                        setDescriptionShowing={this.setDescriptionShowing}
                        getDescriptionShowing={this.getDescriptionShowing}
                        bodyClick={this.bodyClick}
                        closeDescription={this.closeDescription}
                    />
                </div>
            </div>
        );
    }
}

DefaultCustomTableModal.propTypes = {
    customTable: PropTypes.shape({
        fieldGroups: PropTypes.object,
        fields: PropTypes.array,
        failed: PropTypes.bool,
        config: PropTypes.shape({
            infoText: PropTypes.string
        }),
        dataJson: PropTypes.object
    }).isRequired,
    displayedCustomTableFields: PropTypes.arrayOf(
        PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            visibiltyConditionFunc: PropTypes.func,
            isPersistent: PropTypes.bool
        })
    ).isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateDisplayedCustomTableFields: PropTypes.func.isRequired,
    manageFieldsBarShowing: PropTypes.bool.isRequired,
    toggleMangageFieldsBar: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        tablename: PropTypes.string.isRequired
    }).isRequired
};

export default DefaultCustomTableModal;
