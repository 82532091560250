import { createApi, retry } from '@reduxjs/toolkit/query/react';

const { app } = PULSE;
const { API_RETRY_COUNT } = app.common.CONSTANTS;

import { misBaseQuery } from './base-queries/mis-request';

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const cfsApi = createApi({
    reducerPath: 'cfsAPI',
    baseQuery: retry(misBaseQuery(app.environment.cfsApi), {
        maxRetries: API_RETRY_COUNT
    }),
    endpoints: (build) => ({
        getMatchItem: build.query({
            query: (id) => `/matchItem/${id}`,
            transformResponse: modelMatchResponse
        }),
        getInterchange: build.query({
            query: (id) => `/matchInterchange/${id}`
        }),
        getPlayers: build.query({
            query: ({ seasonId, teamIds, position }) =>
                `/players?pageSize=20&pageNum=1&sortBy=name&seasonId=${seasonId}&teamIds=${teamIds}&playerPosition=${position}`,
            transformResponse: (response) => response ?? response
        }),
        getBettingOdds: build.query({
            query: () => `/wagering?application=Web`
        }),
        getLiveLadder: build.query({
            query: ({ roundId }) => `/liveLadder/round/${roundId}`
        }),
        getMatchRosterFull: build.query({
            query: ({ matchId }) => `/matchRoster/full/${matchId}`
        })
    })
});

/**
 * Take in a response from the getMatchItem api call and model the match data
 * by sorting the matchClock and scores so they're ready to work with.
 *
 * @param {object} response - Response from the getMatchItem api call
 * @returns {object} - Response contained a modelled match with sorted data
 */
function modelMatchResponse(response) {
    // Sort the clock into the right order by periodNumber
    if (response.score?.matchClock?.periods?.length) {
        response.score.matchClock.periods.sort(
            (a, b) => b.periodNumber - a.periodNumber
        );
    }

    // Sort score data into the right order by periodNumber
    if (response.score?.homeTeamScore?.periodScore?.length) {
        response.score.homeTeamScore.periodScore.sort((a, b) => {
            return a.periodNumber - b.periodNumber;
        });
    }

    if (response.score?.awayTeamScore?.periodScore?.length) {
        response.score.awayTeamScore.periodScore.sort((a, b) => {
            return a.periodNumber - b.periodNumber;
        });
    }

    return response;
}

export const {
    useGetMatchItemQuery,
    useGetInterchangeQuery,
    useGetPlayersQuery,
    useGetBettingOddsQuery,
    useGetLiveLadderQuery,
    useGetMatchRosterFullQuery
} = cfsApi;
