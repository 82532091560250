import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

export const aflmContentApi = createApi({
    reducerPath: 'aflmContentApi',
    baseQuery: fetchWithRetryBQ(app.environment.aflmContentApi),
    endpoints: (builder) => ({
        getRegions: builder.query({
            query: (pageSize) =>
                `broadcasting/regions?page=0&pageSize=${
                    pageSize ? pageSize : 100
                }`,
            transformResponse: (response) => response?.content
        }),
        // GET /broadcasting/{type}/ - Get Match Events
        getMatchEvents: builder.query({
            query: (params) => {
                return `/broadcasting/match-events${app.common.prepareParams(
                    params
                )}`;
            }
        })
    })
});

export const { useGetRegionsQuery, useGetMatchEventsQuery } = aflmContentApi;
