import {
    createActionCreator,
    createDeltaActionType,
    createSignalAction
} from '../../setup-react';

// ===== Signals ============================================================ //

// Nav
export const UPDATE_NAV_ITEM = createSignalAction(
    'competition-nav',
    'UPDATE_NAV_ITEM'
);

// Filters
export const UPDATE_FILTER_ITEM = createSignalAction(
    'competition-nav',
    'UPDATE_FILTER_ITEM'
);
export const RESET_FILTERS = createSignalAction(
    'competition-nav',
    'RESET_FILTERS'
);

//Live toggle
export const UPDATE_LIVE = createSignalAction('competition-nav', 'UPDATE_LIVE');

// ===== Deltas ============================================================= //

// Nav
export const SET_NAV_ITEM = createDeltaActionType(
    'competition-nav',
    'SET_NAV_ITEM'
);
export const setNavItem = createActionCreator(SET_NAV_ITEM);

// Filters
export const SET_FILTER_ITEM = createDeltaActionType(
    'competition-nav',
    'SET_FILTER_ITEM'
);
export const setFilterItem = createActionCreator(SET_FILTER_ITEM);

export const SET_RESET_FILTERS = createDeltaActionType(
    'competition-nav',
    'SET_RESET_FILTERS'
);
export const setResetFilters = createActionCreator(SET_RESET_FILTERS);

// Betting odds
export const SET_BETTING_ODDS = createDeltaActionType(
    'competition-nav',
    'SET_BETTING_ODDS'
);

// Live toggle
export const SET_LIVE = createDeltaActionType('competition-nav', 'SET_LIVE');

export const setBettingOdds = createActionCreator(SET_BETTING_ODDS);

// Live toggle
export const setLive = createActionCreator(SET_LIVE);
