import React, { useState, useEffect } from 'react';
import { ImageElement } from './ImageElement';

export const PictureElement = ({
    image,
    mediaQueryConfig,
    pictureCssClass = '',
    objectFit = false,
    renderAsPortrait = false,
    lazyload = 'lazy'
}) => {
    const [sourceMarkup, setSourceMarkup] = useState([]);

    let pictureClass = pictureCssClass;
    pictureClass = objectFit
        ? `${pictureClass} object-fit-cover-picture`
        : `${pictureClass} picture`;

    if (renderAsPortrait) {
        pictureClass = `${pictureClass} object-fit-cover-picture--is-portrait`;
    }

    const getSourceConfig = (conf, imageUrls) => {
        let srcset = '';
        imageUrls.forEach((imageUrl, index) => {
            const imageUrlMultiplier = ` ${index + 1}x`;
            srcset = srcset + imageUrl + imageUrlMultiplier;

            if (index !== imageUrls.length - 1) {
                srcset = `${srcset}, `;
            }
        });

        let mediaQuery = [];

        if (conf.minWidth) {
            mediaQuery.push(`(min-width: ${conf.minWidth}px)`);
        }

        if (conf.maxWidth) {
            mediaQuery.push(`(max-width: ${conf.maxWidth}px)`);
        }

        return {
            srcset,
            mediaQuery
        };
    };

    useEffect(() => {
        const markup = [];

        mediaQueryConfig.forEach((conf) => {
            if (conf.name === 'default') {
                const imageUrl = PULSE.app.common.image.getOnDemandImageUrl(
                    image,
                    conf.size.width,
                    conf.size.height
                );
                const objectFitClass = 'picture__img';

                markup.push(
                    <ImageElement
                        url={imageUrl}
                        title={image.title}
                        cssClass={`${conf.cssClass} ${objectFitClass}`}
                        lazyload={lazyload}
                        objectFit={objectFit}
                    />
                );
            } else {
                let imageUrls = [];
                let multipliers = conf.multipliers ? conf.multipliers : [1, 2];

                multipliers.forEach((multiplier) => {
                    imageUrls.push(
                        PULSE.app.common.image.getOnDemandImageUrl(
                            image,
                            conf.size.height * multiplier,
                            conf.size.width * multiplier
                        )
                    );
                });

                const sourceConfig = getSourceConfig(conf, imageUrls);
                markup.push(
                    <source
                        className={conf.cssClass}
                        srcSet={sourceConfig.srcset}
                        media={sourceConfig.mediaQuery}
                    />
                );
            }
        });

        setSourceMarkup(markup);
    }, [image, lazyload, mediaQueryConfig, objectFit]);

    const pictureMarkup = sourceMarkup ? (
        <picture className={pictureClass}>{sourceMarkup}</picture>
    ) : null;

    return pictureMarkup;
};
