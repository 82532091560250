import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '../../SvgIcon';
import { renderEcalSyncButtons } from 'common/react/utils/ecal-react';

export const CalendarButton = ({ ecalId }) => {
    useEffect(() => {
        renderEcalSyncButtons(window.ecalApiKey);
    }, []);

    return (
        <button
            className="competition-nav__button ecal-sync-widget-button"
            data-ecal-widget-id={ecalId}
            data-ecal-no-styling
        >
            <SvgIcon icon="add-to-calendar" className="icon" />
            <span>Add to calendar</span>
        </button>
    );
};

CalendarButton.propTypes = {
    ecalId: PropTypes.string.isRequired
};
