import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { ImageElement } from './ImageElement';
import { getTranslation } from 'common/react/utils/translations';

const CAPTION_VARIANTS = {
    'ProgramCategory:Match Highlights': getTranslation(
        'label.fixtures.match-highlights'
    ),
    'ProgramCategory:Mini-Matches': getTranslation(
        'label.fixtures.mini-matches'
    ),
    'ProgramCategory:Snippet': getTranslation('label.fixtures.snippet'),
    'ProgramCategory:Match Showreels': getTranslation(
        'label.fixtures.match-showreels'
    ),
    'match-preview': getTranslation('label.fixtures.match-preview')
};

export const FixtureMediaThumbnail = ({ media, category }) => {
    const ref = useRef();
    const type = media.type;
    let link = PULSE.app.common.url.generateUrl(media);
    let uiModal = null;
    let uiArgs = null;

    if (type === 'video') {
        uiModal = '#VideoModal';
        const videoUrlParams = {
            pageSize: 50,
            tagNames: `ProgramCategory:${media.tags
                .find((item) => item.label.includes('ProgramCategory:'))
                ?.label.replace('ProgramCategory:', '')}`
        };

        link = `${media.link}&${PULSE.core.url.buildQueryString(
            videoUrlParams
        )}`;

        uiArgs = JSON.stringify({
            contentId: media.id,
            mediaId: media.mediaId,
            mediaTitle: PULSE.app.templating.helper.cleanString(media.title),
            mediaDescription: PULSE.app.templating.helper.cleanString(
                media.description
            ),
            subtitle: PULSE.app.templating.helper.cleanString(media.subtitle),
            type: media.type,
            link: link,
            publishFrom: media.publishFrom + 1,
            mediaDate: PULSE.app.templating.helper.getDateNice(
                media.publishFrom
            )
        });
    }

    useEffect(() => {
        if (ref.current && type === 'video') {
            PULSE.app.VideoPlayerModal.createInstance(ref.current);
            ref.current.dataset.modalAdded = true;
        }
    }, [type]);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    ref={ref}
                    className={`media-thumbnail__absolute-link`}
                    data-ui-modal={uiModal}
                    href={link}
                    data-content-id={media.id}
                    data-ui-args={uiArgs}
                    aria-label={media.title}
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <ImageElement
                        ImageUrl={media.onDemandUrl}
                        ImageTitle={media.title}
                    />

                    {type === 'video' ? (
                        <div className={`media-thumbnail__video-duration`}>
                            <SvgIcon
                                icon={'video-play-small'}
                                className={`icon media-thumbnail__duration-icon`}
                            />
                        </div>
                    ) : null}
                </header>
                <div className={`media-thumbnail__caption`}>
                    {CAPTION_VARIANTS[category]}
                </div>
            </div>
        </article>
    );
};

FixtureMediaThumbnail.propTypes = {
    media: PropTypes.object.isRequired,
    category: PropTypes.string
};
