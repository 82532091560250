import { useMemo } from 'react';

export const useCreateLocations = (data) => {
    return useMemo(() => {
        if (data.length) {
            return data.reduce((regions, region) => {
                const value = PULSE.app.common.getLocationFromTimezone(
                    region.timezone
                );

                const returnValue = {
                    name: value,
                    value,
                    extra: region
                };

                if (!regions.find((obj) => obj.name === returnValue.name)) {
                    regions.push(returnValue);
                }

                return regions.sort((textA, textB) =>
                    textA.name < textB.name
                        ? -1
                        : textA.name > textB.name
                        ? 1
                        : 0
                );
            }, []);
        }
    }, [data]);
};

export const useCreateRegions = (data, selectedLocation) => {
    return useMemo(() => {
        return data
            .filter(
                (region) =>
                    PULSE.app.common.getLocationFromTimezone(
                        region.timezone
                    ) === selectedLocation
            )
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }, [data, selectedLocation]);
};
