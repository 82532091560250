import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getTranslation } from '../utils/translations';
import { SvgIcon } from './SvgIcon';
import { navSelector } from 'store/modules/competition-nav/selectors';
import { filtersSelector as eventsFiltersSelector } from 'store/modules/events-listing/selectors';

export const FilterButton = ({ onChange }) => {
    const [areFilterOptionsVisible, setAreFilterOptionsVisible] =
        useState(false);
    const nav = useSelector(navSelector);
    const homeAwayFilter = nav.homeAway;
    const eventsFilters = useSelector(eventsFiltersSelector);

    const filterSelected =
        homeAwayFilter !== 'all' ||
        eventsFilters?.eventTypes.length > 0 ||
        eventsFilters?.teams.length > 0;

    return (
        <button
            className={classNames(
                'filter-button',
                {
                    'filter-button--active': areFilterOptionsVisible
                },
                {
                    'filter-button--selected': filterSelected
                },
                {
                    'filter-button--selected-no-bar':
                        !areFilterOptionsVisible && filterSelected
                }
            )}
            onClick={() => {
                onChange();
                setAreFilterOptionsVisible((prev) => !prev);
            }}
        >
            <SvgIcon
                icon="filter-circle"
                className="filter-button__icon"
                folder="elements"
                subfolder="stats"
            />
            <SvgIcon
                icon="filter-circle-fill"
                className="filter-button__icon-active"
                folder="elements"
                subfolder="stats"
            />
            {getTranslation('label.statsLeaders.filters.toggleButtonText')}
        </button>
    );
};

FilterButton.propTypes = {
    onChange: PropTypes.func.isRequired
};
