import React from 'react';
import cx from 'classnames';

import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    NextPrevCellRenderer

    Custom cell renderer for Next prev cell to show a clubs next previous game
*/
const NextPrevCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value) {
        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const nextPrevClubIcon = PULSE.app.templating.render(
        {
            cssClass: `custom-table__next-prev-icon`,
            name: `aflc-${value.toLowerCase()}`
        },
        'common.svg-icon'
    );

    return (
        <DefaultCellRendererContainer
            className={cx(className, 'custom-table__cell--next-prev')}
            {...additionalProps}
        >
            <div className="custom-table__cell-content custom-table__cell-content--next-prev">
                <span
                    dangerouslySetInnerHTML={{ __html: nextPrevClubIcon }}
                ></span>
                <span>{value}</span>
            </div>
        </DefaultCellRendererContainer>
    );
};

export default NextPrevCellRenderer;
