import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from './SvgIcon';

/**
 * @param {object} props - Props.
 * @param {string} props.providerId - ProviderId of the team you want to display
 * @param {string} [props.teamAbbreviation] - Team abbreviation. If Abbreviation is provided the providerId is not used
 * @param {string} [props.className] - Class(es).
 * @param {object} [props.style] - Style object.
 * @param {string} [props.theme] - An optional theme modifier e.g. 'light'
 * @returns {JSX.Element} Component.
 */
export const TeamIcon = ({
    providerId,
    teamAbbreviation,
    className = '',
    style = {},
    theme
}) => {
    let teamAbbr = '';
    if (
        typeof teamAbbreviation !== 'undefined' &&
        teamAbbreviation.length > 0
    ) {
        teamAbbr = teamAbbreviation.toLowerCase();
    } else {
        teamAbbr = PULSE.app.common.team.getTeamAbbr(providerId);
    }

    const stateLeague = PULSE.app.common.team
        .getStateLeagueTeams()
        .includes(teamAbbr);

    let modifier = '';
    if (
        !PULSE.app.common.team.getTeamsWithDefaultThemeOnly().includes(teamAbbr)
    ) {
        modifier =
            typeof theme !== 'undefined' && theme !== '' ? '-' + theme : '';
    }

    return (
        <SvgIcon
            className={className}
            icon={`aflc-${teamAbbr}${modifier}`}
            style={style}
            folder={stateLeague ? 'state-league' : ''}
        />
    );
};

TeamIcon.propTypes = {
    providerId: PropTypes.string,
    teamAbbreviation: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    theme: PropTypes.string
};
