import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useGetMatchesQuery } from 'store/modules/api/afl-api';
import { navSelector } from 'store/modules/competition-nav/selectors';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const OtherMatches = ({ roundNumber, inView, parentMatchId }) => {
    const nav = useSelector(navSelector);

    const { matches = [] } = useGetMatchesQuery(
        {
            pageSize: 100,
            competitionId: nav.competition.id,
            compSeasonId: nav.season.id,
            roundNumber: roundNumber
        },
        {
            skip: !inView,
            selectFromResult: ({ data }) => ({
                matches:
                    data &&
                    data.filter(
                        (match) => !parentMatchId || match.id !== parentMatchId
                    )
            })
        }
    );

    return (
        <div className="fixtures__other-matches">
            {matches?.map((match) => (
                <div key={match.id} className="fixtures__other-matches-item">
                    <TeamIcon
                        className="fixtures__match-team-logo"
                        providerId={match.home.team.providerId}
                    />

                    {PULSE.app.common.match.getMatchStatus(match.status) ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED ? (
                        <p className="fixtures__other-matches-score">
                            {match.home?.score?.totalScore}
                        </p>
                    ) : (
                        <p className="fixtures__other-matches-abbr">
                            {match.home?.team?.abbreviation}
                        </p>
                    )}

                    <SvgIcon icon="versus" className="icon" />

                    {PULSE.app.common.match.getMatchStatus(match.status) ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED ? (
                        <p className="fixtures__other-matches-score">
                            {match.away?.score?.totalScore}
                        </p>
                    ) : (
                        <p className="fixtures__other-matches-abbr">
                            {match.away?.team?.abbreviation}
                        </p>
                    )}

                    <TeamIcon
                        className="fixtures__match-team-logo"
                        providerId={match.away.team.providerId}
                    />
                </div>
            ))}
        </div>
    );
};

OtherMatches.propTypes = {
    roundNumber: PropTypes.number.isRequired,
    inView: PropTypes.bool,
    parentMatchId: PropTypes.number
};
