import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import {
    useGetVenuesQuery,
    useGetMatchesQuery
} from 'store/modules/api/afl-api';

import { SelectMultiple } from '../../select-multiple';

import { UPDATE_FILTER_ITEM } from 'store/modules/competition-nav/actions';
import { getTranslation } from '../../../utils/translations';

export const CityFilterItem = ({ addOnResetCallback, currentFilters }) => {
    const dispatch = useDispatch();

    const { competitionId, compSeason } = currentFilters;
    const filters = useSelector(filtersSelector);

    const { data: venuesList } = useGetVenuesQuery(competitionId, {
        skip: !competitionId ? true : false
    });

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    const cities = useMemo(
        () =>
            venuesList
                ? [...new Set(venuesList.map((item) => item.location))]
                : null,
        [venuesList]
    );

    // if team or venue selected
    // disable non-applicable cities

    const { data: matches = [] } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            ...(filters.teams.length && { teamId: filters.teams }),
            ...(filters.venues.length && { venueId: filters.venues })
        },
        {
            skip: !competitionId || !compSeason.id
        }
    );

    let activeCities = [];

    if (filters.teams.length || filters.venues.length) {
        if (matches.length) {
            matches.forEach((match) => {
                if (!activeCities.includes(match.venue.location)) {
                    activeCities = [...activeCities, match.venue.location];
                }
            });
        }
    }

    return (
        <SelectMultiple
            items={cities}
            getInitialSelectedItems={() => {
                return cities.filter((c) => filters.cities.includes(c));
            }}
            label={getTranslation('label.fixtures.filters.city')}
            icon="city"
            onSelectedItemsChange={(city) => {
                updateFilterItem(
                    'cities',
                    city.map((c) => c)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                >
                    <span style={{ marginRight: '1rem' }}>{item}</span>
                </span>
            )}
            getItemDisabled={(item) =>
                activeCities.length && !activeCities.includes(item)
            }
        />
    );
};

CityFilterItem.propTypes = {
    addOnResetCallback: PropTypes.func,
    currentFilters: PropTypes.object
};
