import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from '../../../statspro/js/components/common/stats-section';

import {
    RoundSelectNavItem,
    SeasonNavItem,
    CompNavItem,
    ResponsiveSeasonNavItem,
    ResponsiveRoundNavItem,
    LiveToggleNavItem
} from 'common/react/components/competition-nav/nav-items';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';

export const LadderNav = ({
    competitionId,
    seasonId,
    competitionProviderId,
    seasonPid
}) => {
    const windowSize = useWindowSize();

    const moveTogglesBreakpoint = 1115;

    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    const moveToggles = windowSize.width < moveTogglesBreakpoint;

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const initialValues = {
        competitionId,
        seasonId,
        competitionProviderId,
        seasonPid
    };

    const nav = useSelector(navSelector);

    const options = useCompetitionNavigationOptions(nav);

    useNavSync({ ...options, initialValues, removeFinals: true });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const currentFilters = useGetCurrentCompFilters();

    return (
        <div className="competition-nav">
            <StatsSection.Nav>
                <StatsSection.NavItems>
                    <CompNavItem
                        competitions={competitions}
                        hidePreseason={true}
                    />

                    {!showResponsiveItems ? (
                        <SeasonNavItem seasons={seasons} />
                    ) : null}
                    {!showResponsiveItems ? (
                        <RoundSelectNavItem
                            removeFinals={true}
                            classes={'u-margin-right-auto'}
                        />
                    ) : null}

                    {!showResponsiveItems &&
                    !moveToggles &&
                    currentFilters?.round?.providerId ? (
                        <LiveToggleNavItem
                            roundId={currentFilters.round.providerId}
                            classes={
                                'competition-nav__live-toggle-wrapper competition-nav__live-toggle-wrapper--ladder'
                            }
                        />
                    ) : null}
                </StatsSection.NavItems>

                {showResponsiveItems ? (
                    <StatsSection.NavFilterItems hideReset={true}>
                        <ResponsiveSeasonNavItem seasons={seasons} />
                        <ResponsiveRoundNavItem removeFinals={true} />
                    </StatsSection.NavFilterItems>
                ) : null}
            </StatsSection.Nav>

            <div className="competition-nav__toggles-wrapper">
                {(showResponsiveItems || moveToggles) &&
                currentFilters?.round?.providerId ? (
                    <LiveToggleNavItem
                        roundId={currentFilters.round.providerId}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

LadderNav.propTypes = {
    competitionId: PropTypes.string,
    competitionProviderId: PropTypes.string,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.string
};
