import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

import { BroadcasterPromo } from './broadcaster-promo/BroadcasterPromo';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { getTranslation } from 'common/react/utils/translations';
import { FixturesDateHeader } from './FixturesDateHeader';
import { FixturesRoundHeader } from './FixturesRoundHeader';
import { Fixture } from './Fixture';
import { OtherMatches } from '../other-matches/OtherMatches';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

const { PROVIDER_IDS } = PULSE.app.common.CONSTANTS;

export const FixturesItem = ({
    round,
    items,
    broadcastGuideUrl,
    broadcasterPromo,
    firstLiveOrUpcomingFixture,
    scrollToRound,
    setScrollToRound,
    teamId,
    roundNumber
}) => {
    const isTBC =
        !items ||
        PULSE.app.common.match.getMatchStatus(items[0].status) ===
            PULSE.app.common.CONSTANTS.MATCH_STATUS.TBC ||
        PULSE.app.common.match.cancelledOrPostponed(items[0].status);
    const nav = useSelector(navSelector);

    const selectedRegion = useSingleSiteSetting('region');

    const reportLabel = PULSE.app.common.url.urlify(
        getTranslation('label.mc.reportTab')
    );

    const mcLink = [
        PROVIDER_IDS.PRE_SEASON,
        PROVIDER_IDS.PREMIERSHIP,
        PROVIDER_IDS.VFL_PREMIERSHIP,
        PROVIDER_IDS.VFL_WOMENS,
        PROVIDER_IDS.WAFL_PREMIERSHIP,
        PROVIDER_IDS.WOMENS,
        PROVIDER_IDS.SANFL_PREMIERSHIP
    ].includes(nav?.competition.providerId);

    const { ref, inView } = useInView({
        rootMargin: `64px 0px 0px 0px`
    });

    // is bye round
    if (
        round.byes.length &&
        round.byes.find((bye) => bye.id === parseInt(teamId))
    ) {
        return typeof roundNumber === 'undefined' ||
            (typeof roundNumber !== 'undefined' &&
                roundNumber === round.roundNumber) ? (
            <div ref={ref} className="fixtures__bye-round-wrapper">
                <div className="fixtures__header-wrapper">
                    <h2 className="fixtures__date-header">
                        {getTranslation('label.fixtures.group.title.bye')}
                    </h2>
                    <h3 className="fixtures__round-header">{round.name}</h3>
                </div>

                {nav?.otherMatches.isActive ? (
                    <OtherMatches
                        roundNumber={round.roundNumber}
                        inView={inView}
                    />
                ) : (
                    ''
                )}
            </div>
        ) : (
            <></>
        );
    }

    return (
        <>
            {items?.map((match) => (
                <div ref={ref} key={match.id} style={{ position: 'relative' }}>
                    {!isTBC ? (
                        <div className="fixtures__header-wrapper">
                            <FixturesDateHeader
                                dateKey={moment(match.utcStartTime)
                                    .tz(selectedRegion.timezone)
                                    .format('YYYYMMDD')}
                            />
                            <FixturesRoundHeader
                                roundLabel={match.round.name}
                            />
                        </div>
                    ) : (
                        <div className="fixtures__tbc-header-wrapper">
                            <FixturesDateHeader
                                label={getTranslation(
                                    `label.fixtures.${PULSE.app.common.match
                                        .getMatchStatus(items[0].status)
                                        .toLowerCase()}`
                                )}
                            />
                            <FixturesRoundHeader
                                roundLabel={match.round.name}
                            />
                        </div>
                    )}

                    <ErrorBoundary key={match.id} fallback={<></>}>
                        <Fixture
                            mcLink={mcLink}
                            match={match}
                            reportLabel={reportLabel}
                            broadcastGuideUrl={broadcastGuideUrl}
                            roundNumber={match.round?.roundNumber}
                            inView={inView}
                            firstLiveOrUpcomingFixture={
                                firstLiveOrUpcomingFixture
                            }
                            scrollToRound={scrollToRound}
                            setScrollToRound={setScrollToRound}
                        />

                        {broadcasterPromo &&
                            isMobile &&
                            match.id === firstLiveOrUpcomingFixture && (
                                <BroadcasterPromo promo={broadcasterPromo} />
                            )}
                    </ErrorBoundary>
                </div>
            ))}
        </>
    );
};

FixturesItem.propTypes = {
    items: PropTypes.array,
    broadcastGuideUrl: PropTypes.string,
    round: PropTypes.object,
    broadcasterPromo: PropTypes.object,
    firstLiveOrUpcomingFixture: PropTypes.number,
    scrollToRound: PropTypes.bool,
    setScrollToRound: PropTypes.func,
    teamId: PropTypes.any.isRequired,
    roundNumber: PropTypes.number
};
