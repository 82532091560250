import React from 'react';
import PropTypes from 'prop-types';

import { FixtureLinks } from './FixtureLinks';
import { FixtureMedia } from '../fixture-media/FixtureMedia';

export const ResponsiveFixtureLinks = ({
    match,
    matchStatus,
    metadata,
    broadcastGuideUrl,
    reportLabel,
    inView
}) => {
    return (
        <div
            className={`fixtures__responsive-links ${
                matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE
                    ? 'fixtures__responsive-links--live'
                    : ''
            }`}
        >
            {matchStatus ===
                PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED && (
                <FixtureMedia
                    match={match}
                    matchStatus={matchStatus}
                    inView={inView}
                />
            )}

            <FixtureLinks
                match={match}
                matchStatus={matchStatus}
                metadata={metadata}
                broadcastGuideUrl={broadcastGuideUrl}
                reportLabel={reportLabel}
            />
        </div>
    );
};

ResponsiveFixtureLinks.propTypes = {
    match: PropTypes.object,
    matchStatus: PropTypes.string,
    metadata: PropTypes.object,
    broadcastGuideUrl: PropTypes.string,
    reportLabel: PropTypes.string,
    inView: PropTypes.bool.isRequired
};
