import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useGetEventsQuery } from 'common/store/modules/api/content-api';
import { navSelector } from 'common/store/modules/events-listing/selectors';

import { filterEvents } from '../utils/filter-events';
import { CardSkeleton } from './cards/CardSkeleton';
import { EventsListingByFilter } from './EventsListingByFilter';
import { EventsListingByDate } from './EventsListingByDate';
import { sortByDate } from '../utils/sort-by-date';

export const EventsListingCards = ({
    eventId,
    compseasonId,
    localTimezone
}) => {
    const nav = useSelector(navSelector);

    const timezone = localTimezone ? localTimezone : 'Australia/Melbourne';

    const activeStartDate = `${moment(nav?.date).format(
        'YYYY-MM-DD 00:00:00'
    )}`;
    const activeEndDate = `${moment(nav?.date).format('YYYY-MM-DD 23:59:59')}`;

    const dateBounds = {
        start: moment
            .tz(activeStartDate, timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
        end: moment
            .tz(activeEndDate, timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss')
    };

    const contentFilter = {
        rangeFilter: {
            lowerBound: {
                fieldName: 'Date & Time',
                dvalue: `${dateBounds.start}.000Z`
            },
            upperBound: {
                fieldName: 'Date & Time',
                dvalue: `${dateBounds.end}.000Z`
            }
        }
    };

    const objJsonStr = JSON.stringify(contentFilter);
    const encodedString = btoa(objJsonStr);

    const filterParams = {
        // Only filter by date range if no other filters are active
        contentFilter:
            !nav?.filters?.eventTypes?.length > 0 &&
            !nav?.filters?.eventLocations?.length > 0 &&
            !nav?.filters?.teams?.length > 0
                ? encodedString
                : ''
    };

    const { events = [], isEventsFetching } = useGetEventsQuery(
        {
            eventId: eventId,
            params: filterParams
        },
        {
            skip: !eventId || !nav?.date,
            selectFromResult: ({ data, isFetching }) => ({
                events:
                    data &&
                    (nav.filters.teams.length ||
                        nav.filters.eventTypes.length |
                            nav.filters.eventLocations.length)
                        ? filterEvents(data, nav.filters)
                        : data,
                isEventsFetching: isFetching
            })
        }
    );

    const sorted = useMemo(() => {
        return sortByDate(events);
    }, [events]);

    if (isEventsFetching) {
        return (
            <div className="events-listing">
                <div className="events-listing__header"></div>
                <CardSkeleton />
            </div>
        );
    }

    return !nav?.filters?.eventTypes?.length > 0 &&
        !nav?.filters?.eventLocations?.length > 0 &&
        !nav?.filters?.teams?.length > 0 ? (
        <EventsListingByDate
            eventsArray={sorted}
            localTimezone={localTimezone}
            compseasonId={compseasonId}
            activeStartDate={activeStartDate}
        />
    ) : (
        <EventsListingByFilter
            eventsArray={sorted}
            localTimezone={localTimezone}
            compseasonId={compseasonId}
        />
    );
};

EventsListingCards.propTypes = {
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string,
    eventId: PropTypes.string.isRequired
};
