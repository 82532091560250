import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { FixtureMediaThumbnail } from './FixtureMediaThumbnail';
import { ResponsiveFixtureMediaThumbnail } from './ResponsiveFixtureMediaThumbnail.jsx';

import { useGetContentQuery } from 'store/modules/api/content-api';

const PAGE_SIZE = 10;

const PROGRAM_CATEGORIES = {
    highlights: 'ProgramCategory:Match Highlights',
    miniMatches: 'ProgramCategory:Mini-Matches',
    snippet: 'ProgramCategory:Snippet',
    showreels: 'ProgramCategory:Match Showreels',
    preview: 'match-preview'
};

const THUMBNAIL_VARIANT = [
    {
        name: 'default',
        size: { width: 152, height: 86 },
        multipliers: [1, 2, 3]
    }
];

const TAG_GROUPS = {
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING]: [
        PROGRAM_CATEGORIES.preview
    ],
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED]: [
        PROGRAM_CATEGORIES.highlights,
        PROGRAM_CATEGORIES.miniMatches,
        PROGRAM_CATEGORIES.snippet,
        PROGRAM_CATEGORIES.showreels
    ]
};

const CONTENT_TYPES = {
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING]: 'text',
    [PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED]: 'video'
};

export const FixtureMedia = ({ match, matchStatus, inView }) => {
    const [category, setCategory] = useState('');

    const tagList = TAG_GROUPS[matchStatus]
        ? TAG_GROUPS[matchStatus].map((item) => `"${item}"`)
        : [];

    const requestConfig = {
        type: CONTENT_TYPES[matchStatus],
        params: {
            pageSize: PAGE_SIZE,
            references: `AFL_MATCH:${match.id}`,
            tagExpression: `(${tagList?.join('or')})`
        }
    };

    const { data: requestData = [] } = useGetContentQuery(requestConfig, {
        skip: !inView
    });
    const mediaData = requestData?.content;

    const media = useMemo(() => {
        if (matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING) {
            return PULSE.app.common.content.getContentModel(
                mediaData?.find((item) =>
                    item.tags.find((tag) => {
                        setCategory(PROGRAM_CATEGORIES.preview);
                        return tag.label === PROGRAM_CATEGORIES.preview;
                    })
                ),
                THUMBNAIL_VARIANT
            );
        }

        return PULSE.app.common.content.getContentModel(
            mediaData?.find((item) =>
                item.tags.find((tag) => {
                    setCategory(PROGRAM_CATEGORIES.highlights);
                    return tag.label === PROGRAM_CATEGORIES.highlights;
                })
            ) ||
                mediaData?.find((item) =>
                    item.tags.find((tag) => {
                        setCategory(PROGRAM_CATEGORIES.miniMatches);
                        return tag.label === PROGRAM_CATEGORIES.miniMatches;
                    })
                ) ||
                mediaData?.find((item) =>
                    item.tags.find((tag) => {
                        setCategory(PROGRAM_CATEGORIES.snippet);
                        return tag.label === PROGRAM_CATEGORIES.snippet;
                    })
                ) ||
                mediaData?.find((item) =>
                    item.tags.find((tag) => {
                        setCategory(PROGRAM_CATEGORIES.showreels);
                        return tag.label === PROGRAM_CATEGORIES.showreels;
                    })
                ),
            THUMBNAIL_VARIANT
        );
    }, [mediaData]);

    return (
        <>
            {media && (
                <>
                    <FixtureMediaThumbnail media={media} category={category} />
                    {matchStatus ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED ? (
                        <ResponsiveFixtureMediaThumbnail
                            video={media}
                            category={category}
                        />
                    ) : null}
                </>
            )}
        </>
    );
};

FixtureMedia.propTypes = {
    match: PropTypes.object.isRequired,
    matchStatus: PropTypes.string,
    inView: PropTypes.bool.isRequired
};
