import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

const CAPTION_VARIANTS = {
    'ProgramCategory:Match Highlights': getTranslation(
        'label.fixtures.match-highlights'
    ),
    'ProgramCategory:Mini-Matches': getTranslation(
        'label.fixtures.mini-matches'
    ),
    'ProgramCategory:Snippet': getTranslation('label.fixtures.snippet'),
    'ProgramCategory:Match Showreels': getTranslation(
        'label.fixtures.match-showreels'
    ),
    'match-preview': getTranslation('label.fixtures.match-preview')
};

export const ResponsiveFixtureMediaThumbnail = ({ video, category }) => {
    const ref = useRef();

    const videoUrlParams = {
        pageSize: 50,
        tagNames: `ProgramCategory:${video.tags
            .find((item) => item.label.includes('ProgramCategory:'))
            ?.label.replace('ProgramCategory:', '')}`,
        references: `AFL_MATCH:${
            video.references.find((item) => item.type === 'AFL_MATCH')?.id
        },AFL_ROUND:${
            video.references.find((item) => item.type === 'AFL_ROUND')?.id
        }`
    };

    const videoLink = `${video.link}&${PULSE.core.url.buildQueryString(
        videoUrlParams
    )}`;

    const videoModalData = {
        contentId: video.id,
        mediaId: video.mediaId,
        mediaTitle: PULSE.app.templating.helper.cleanString(video.title),
        mediaDescription: PULSE.app.templating.helper.cleanString(
            video.description
        ),
        subtitle: PULSE.app.templating.helper.cleanString(video.subtitle),
        type: video.type,
        link: videoLink,
        publishFrom: video.publishFrom + 1,
        mediaDate: PULSE.app.templating.helper.getDateNice(video.publishFrom)
    };

    useEffect(() => {
        if (ref.current) {
            PULSE.app.VideoPlayerModal.createInstance(ref.current);
            ref.current.dataset.modalAdded = true;
        }
    }, []);

    return (
        <a
            ref={ref}
            className="fixtures__link-button fixtures__media-button button-new"
            data-ui-modal="#VideoModal"
            href={videoLink}
            data-content-id={video.id}
            data-ui-args={JSON.stringify(videoModalData)}
        >
            <SvgIcon
                icon={'video-playback-new'}
                className={`icon fixtures__button-icon`}
            />
            <span>{CAPTION_VARIANTS[category]}</span>
        </a>
    );
};

ResponsiveFixtureMediaThumbnail.propTypes = {
    video: PropTypes.object.isRequired,
    category: PropTypes.string
};
