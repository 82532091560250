import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader } from 'common/react/components/Loader';
import {
    playerStatsDataTypeSelector,
    playerStatsNavSelector,
    playerStatsCustomFieldsSelector
} from 'store/modules/match-centre/player-stats/selectors';
import { EmptyState } from 'common/react/components/EmptyState';
import { Table } from './components/Table';
import { composeRowData, composeColumnData } from './utils';
import { useDataType } from 'widgets/statspro/js/components/common/hooks/use-data-type';
import { UPDATE_DATA_TYPE } from 'store/modules/match-centre/player-stats/actions';
import { updateContainerDataType } from './utils/update-container-data-type';

export const PlayerStatsTable = ({
    matchId,
    homePid,
    awayPid,
    homeNickname,
    awayNickname
}) => {
    const matchData = useSelector((state) =>
        PULSE.app.redux.selectors.playerstats.scope(state, matchId)
    );
    const nav = useSelector(playerStatsNavSelector);
    const initialDataType = useSelector(playerStatsDataTypeSelector);
    const { dataType, updateDataType } = useDataType({
        initialDataType,
        onUpdateActionCreator: UPDATE_DATA_TYPE.request
    });
    const [rowData, setRowData] = useState([]);
    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [noPlayerData, setNoPlayerData] = useState(false);
    const INITIAL_DATA_REQUESTS = 2;

    const homeAbbr = PULSE.app.common.team.getTeamAbbr(
        matchData?.playerAverages?.find((item) => item.team.teamId === homePid)
            ?.team?.teamId ||
            matchData?.players?.find((item) => item.teamId === homePid)?.teamId
    );

    const awayAbbr = PULSE.app.common.team.getTeamAbbr(
        matchData?.playerAverages?.find((item) => item.team.teamId === awayPid)
            ?.team?.teamId ||
            matchData?.players?.find((item) => item.teamId === awayPid)?.teamId
    );

    const customFields = useSelector(playerStatsCustomFieldsSelector);
    const [requestsComplete, setRequestsComplete] = useState(
        matchData?.requestsComplete
    );

    useEffect(() => {
        // Check for requests to complete
        if (
            matchData?.requestsComplete &&
            matchData?.requestsComplete !== requestsComplete
        ) {
            setRequestsComplete(matchData?.requestsComplete);
        }
    }, [matchData?.requestsComplete]);

    useEffect(() => {
        // Update the data with nav values when requests have completed
        setNoDataAvailable(false);

        if (
            matchData?.requestsComplete &&
            matchData.requestsComplete >= INITIAL_DATA_REQUESTS &&
            homeAbbr
        ) {
            let playersData = matchData.players ?? [];
            if (playersData.length) {
                // Team filters
                if (nav.teams === homeNickname) {
                    playersData = matchData.players.filter(
                        (item) => item.teamId === homePid
                    );
                } else if (nav.teams === awayNickname) {
                    playersData = matchData.players.filter(
                        (item) => item.teamId === awayPid
                    );
                }

                // Set data
                if (dataType.type === 'totals') {
                    if (!noPlayerData) {
                        updateDataType('totals');
                        setRowData(playersData);
                    }
                } else if (dataType.type === 'averages') {
                    if (matchData.playerAverages?.length) {
                        setRowData(
                            matchData.playerAverages?.filter((item) =>
                                playersData.find(
                                    (playerItem) =>
                                        playerItem.player.player.player
                                            .playerId === item.player.playerId
                                )
                            )
                        );
                    } else {
                        setNoDataAvailable(true);
                    }
                }
            } else if (matchData.playerAverages?.length) {
                // When there is no player data yet for upcoming matches,
                // set the initial data to use season averages
                if (dataType.type === 'totals') {
                    if (!noPlayerData) {
                        updateDataType('averages');
                        setNoPlayerData(true);
                    } else {
                        setNoDataAvailable(true);
                    }
                } else {
                    setRowData(
                        nav.teams === 'Both'
                            ? matchData.playerAverages
                            : matchData.playerAverages.filter(
                                  (item) => item.team.teamNickname === nav.teams
                              )
                    );
                }
            }
        } else {
            // When everything else fails, setNoDataAvailable to true
            // to prevent an infinity loader
            setNoDataAvailable(true);
        }
    }, [
        matchData,
        requestsComplete,
        dataType.type,
        nav.stats,
        nav.teams,
        homeAbbr
    ]);

    useEffect(() => {
        updateContainerDataType(nav.stats);
    }, [nav.stats]);

    /**
     * Get columns.
     */
    const columns = useMemo(
        () => composeColumnData(nav.stats, customFields),
        [nav.stats, rowData, customFields]
    );

    /**
     * Get rows.
     */
    const data = useMemo(
        () =>
            rowData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['jumperNumber', 'player', 'empty'].includes(
                                col.accessor
                            )
                    ),
                    dataType.type,
                    noPlayerData,
                    homeAbbr,
                    awayAbbr,
                    homePid
                )
            ),
        [columns]
    );

    if (!data.length && !noDataAvailable) {
        // Loader for waiting for data
        return (
            <div className="mc-player-stats-table__loader">
                <Loader />
            </div>
        );
    } else if (noDataAvailable) {
        // Error handling for when there is no data
        return (
            <div className="mc-player-stats-table__empty-state">
                <EmptyState
                    titleTranslation="label.stats.error.noStatsAvailable"
                    summaryTranslation="label.stats.error.noStatsAvailable.summary"
                />
            </div>
        );
    }

    return (
        <Table
            key={nav.stats + customFields.length}
            columns={columns}
            data={data}
            modifier="player-stats"
        />
    );
};

PlayerStatsTable.propTypes = {
    matchId: PropTypes.string.isRequired,
    homePid: PropTypes.string.isRequired,
    awayPid: PropTypes.string.isRequired,
    homeNickname: PropTypes.string.isRequired,
    awayNickname: PropTypes.string.isRequired
};
