import { useCallback, useEffect, useState } from 'react';

export const useIsScrollable = (ref, wrapperRef) => {
    const updateIsScrollable = useCallback(() => {
        const { current: scrollableWrapper } = ref;
        const { current: wrapper } = wrapperRef;

        if (!scrollableWrapper) {
            return;
        }

        setIsScrollable(scrollableWrapper.clientWidth > wrapper.clientWidth);
    }, [ref]);

    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        updateIsScrollable();

        window.addEventListener('resize', updateIsScrollable);

        return () => window.removeEventListener('resize', updateIsScrollable);
    }, [ref]);

    return isScrollable;
};
