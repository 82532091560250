import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SKELETON_COUNT = 3;

export const FixturesSkeleton = () => {
    return (
        <>
            <div className="fixtures__group-divider">
                <div className="fixtures__group-date">
                    <Skeleton width={200} />
                </div>
            </div>
            <Skeleton
                className="fixtures__item-skeleton"
                count={SKELETON_COUNT}
            />
        </>
    );
};
