import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @returns {JSX.Element} Component.
 */
export const StatsSection = (props) => {
    const children = React.Children.toArray(props.children);

    if (!children?.length) {
        return <></>;
    }

    const Heading = children.find(
        (child) => child.props.name === 'StatsSectionHeading'
    );
    const Nav = children.find(
        (child) => child.props.name === 'StatsSectionNav'
    );
    const Content = children.find(
        (child) => child.props.name === 'StatsSectionContent'
    );

    return (
        <>
            {!!Heading && <div className="wrapper">{Heading}</div>}
            <div className="stats-section-wrapper wrapper pb-8 mb-0">
                {Nav}
                {Content}
            </div>
        </>
    );
};

StatsSection.propTypes = {
    children: PropTypes.any
};
