import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const Tag = ({ name, icon }) => {
    return (
        <div className="events-listing__tags-item">
            {icon && (
                <div className="events-listing__tags-icon">
                    <SvgIcon
                        className="icon"
                        folder={'elements/events-and-locations'}
                        icon={icon}
                    />
                </div>
            )}

            {name}
        </div>
    );
};

Tag.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string
};
