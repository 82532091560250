import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '../../select';
import { getTranslation } from 'common/react/utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';

export const HomeAwayNavItem = () => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    const items = [
        {
            name: getTranslation('label.filters.allMatches'),
            value: 'all'
        },
        {
            name: getTranslation('label.filters.homeMatches'),
            value: 'home'
        },
        {
            name: getTranslation('label.filters.awayMatches'),
            value: 'away'
        }
    ];

    return (
        <div className="stats-leaders-nav__select">
            <Select
                items={items}
                itemToString={(item) => (item?.name ? item.name : '')}
                activeItem={items.find((i) => i.value === nav.homeAway)}
                initialSelectedItem={items.find(
                    (i) => i.value === nav.homeAway
                )}
                label={getTranslation('label.filters.homeAway')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'homeAway',
                            value: selectedItem.value
                        })
                    );
                }}
            />
        </div>
    );
};
