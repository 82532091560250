import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const { app } = PULSE;
const { MIS_TOKEN_HEADER_PROP } = app.common.CONSTANTS;

// RTK Query update: Create a baseQuery instance with headers
// RTK Query update: There should be one baseQuery instance for each API
const baseQuery = fetchBaseQuery({
    baseUrl: app.environment.cfsCommentary,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().token;
        if (token) {
            headers.set(MIS_TOKEN_HEADER_PROP, token);
            return headers;
        }
        throw new Error('Token not available, cancelling request');
    }
});

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const cfsCommentaryApi = createApi({
    reducerPath: 'cfsCommentaryAPI',
    baseQuery: retry(baseQuery, { maxRetries: 6 }),
    endpoints: (build) => ({
        getComms: build.query({
            query: (id) => `/${id}`,
            transformResponse: (response) => {
                if (response.lastUpdated) {
                    delete response.lastUpdated;
                }
                return response;
            }
        })
    })
});
