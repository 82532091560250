import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { configureReduxQuerySync } from 'common/react/components/competition-nav/utils/configure-redux-query-sync';
import { Fixtures } from '../components/Fixtures';
import { NoFilterFixtures } from '../components/NoFilterFixtures';

export const FixturesProvider = ({ store, data }) => {
    useEffect(() => {
        configureReduxQuerySync(store);
    }, [store]);

    return (
        <Provider store={store}>
            {data.noFilterMode ? (
                <NoFilterFixtures {...data} />
            ) : (
                <Fixtures {...data} />
            )}
        </Provider>
    );
};

export default FixturesProvider;

FixturesProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
