import produce from 'immer';

// @ts-ignore
import { LeaderboardState } from '../types';
// eslint-disable-next-line no-unused-vars
import { Action } from '../../../../types';
import { SET_LEADERBOARD_DATA } from '../actions';
import {} from './';
import { setLeaderboardData } from './set-leaderboard-data';

/**
 * @type {LeaderboardState}
 */
const initialState = {
    requests: [],
    data: {}
};

/**
 * @param {LeaderboardState} state - The Redux state.
 * @param {Action} action - The Redux action.
 * @returns {LeaderboardState} The updated Redux state.
 */
export const leaderboardReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case SET_LEADERBOARD_DATA:
                setLeaderboardData(draft, action.payload);
                break;
            default:
                draft;
        }
    });
};
