import { getTranslation } from 'common/react/utils/translations';

export const composeColumnData = (windowWidth) => {
    let cols = [];

    // display abbreviated columns in mobile
    if ((windowWidth ?? innerWidth) < PULSE.app.measurements.desktop) {
        cols = [
            {
                Header: getTranslation(`label.ladder.pos.short`),
                accessor: 'position'
            },
            {
                Header: getTranslation(`label.ladder.club`),
                accessor: 'club'
            },
            {
                Header: '',
                accessor: 'clubName'
            },
            {
                Header: getTranslation(`label.ladder.played.short`),
                accessor: 'played'
            },
            {
                Header: getTranslation(`label.ladder.winRatio.short`),
                accessor: 'winRatio'
            },
            {
                Header: getTranslation(`label.ladder.points.short`),
                accessor: 'points'
            },
            {
                Header: '%',
                accessor: 'percentage'
            },
            {
                Header: getTranslation(`label.ladder.won.short`),
                accessor: 'won'
            },
            {
                Header: getTranslation(`label.ladder.lost.short`),
                accessor: 'lost'
            },
            {
                Header: getTranslation(`label.ladder.drawn.short`),
                accessor: 'drawn'
            },
            {
                Header: getTranslation(`label.ladder.pointsFor.short`),
                accessor: 'pointsFor'
            },
            {
                Header: getTranslation(`label.ladder.pointsAgainst.short`),
                accessor: 'pointsAgainst'
            },
            {
                Header: getTranslation(`label.ladder.clubForm`),
                accessor: 'form'
            },
            {
                Header: getTranslation('label.ladder.upNext'),
                accessor: 'nextOpponent'
            },
            {
                Header: 'Position Gained/Lost (HIDDEN CELL)',
                accessor: 'positionChange'
            },
            {
                Header: 'Is Playing (HIDDEN CELL)',
                accessor: 'isPlaying'
            }
        ];
    } else {
        cols = [
            {
                Header: getTranslation(`label.ladder.pos`),
                accessor: 'position'
            },
            {
                Header: getTranslation(`label.ladder.club`),
                accessor: 'club'
            },
            {
                Header: '',
                accessor: 'clubName'
            },
            {
                Header: getTranslation(`label.ladder.played`),
                accessor: 'played'
            },
            {
                Header: getTranslation(`label.ladder.winRatio`),
                accessor: 'winRatio'
            },
            {
                Header: getTranslation(`label.ladder.points`),
                accessor: 'points'
            },
            {
                Header: '%',
                accessor: 'percentage'
            },
            {
                Header: getTranslation(`label.ladder.won`),
                accessor: 'won'
            },
            {
                Header: getTranslation(`label.ladder.lost`),
                accessor: 'lost'
            },
            {
                Header: getTranslation(`label.ladder.drawn`),
                accessor: 'drawn'
            },
            {
                Header: getTranslation(`label.ladder.pointsFor.short`),
                accessor: 'pointsFor'
            },
            {
                Header: getTranslation(`label.ladder.pointsAgainst.short`),
                accessor: 'pointsAgainst'
            },
            {
                Header: getTranslation(`label.ladder.clubForm`),
                accessor: 'form'
            },
            {
                Header: getTranslation('label.ladder.upNext'),
                accessor: 'nextOpponent'
            },
            {
                Header: 'Position Gained/Lost (HIDDEN CELL)',
                accessor: 'positionChange'
            },
            {
                Header: 'Is Playing (HIDDEN CELL)',
                accessor: 'isPlaying'
            }
        ];
    }

    return cols;
};
