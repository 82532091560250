/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TooltipButton } from 'common/react/components/tooltip-button';
export const StatsTableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    getBenchmark,
    primaryTooltipComponent,
    getCellId,
    getCellTitle,
    customCells,
    skipCells,
    valueWrapper,
    // eslint-disable-next-line no-empty-function
    getTrProps = () => {}
}) => (
    <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
            prepareRow(row);

            const isActiveClubRow = row?.original?.isActiveClub
                ? 'is-active-club'
                : '';

            const isQualiferRow =
                rowIndex === row?.original?.qualiferRow
                    ? 'is-qualifier-row'
                    : '';

            const isConferenceRow =
                row?.original?.isConference && rowIndex === 1
                    ? 'is-conference-row'
                    : '';

            const isLiveRow = row?.values?.isPlaying ? 'is-live' : '';

            const cells = row.cells.filter((cell) => {
                if (!skipCells) {
                    return true;
                }
                return !skipCells.includes(cell.column.id);
            });

            return (
                <tr
                    {...row.getRowProps()}
                    className={`stats-table__body-row ${isQualiferRow} ${isConferenceRow} ${isLiveRow} ${isActiveClubRow}`}
                    {...getTrProps(row)}
                >
                    {cells.map((cell) => {
                        const cellProps = cell.getCellProps();

                        if (customCells && customCells[cell.column.id]) {
                            return customCells[cell.column.id](
                                row,
                                cell,
                                cellProps
                            );
                        }

                        const benchmark = getBenchmark
                            ? getBenchmark(row, cell)
                            : null;
                        const value = cell.value ?? 0;

                        return (
                            <td
                                {...cellProps}
                                className={classnames('stats-table__cell', {
                                    'stats-table__cell--sorted':
                                        cell.column.isSorted
                                })}
                            >
                                <ConditionalWrapper
                                    condition={valueWrapper}
                                    wrapper={(children) => (
                                        <div
                                            className={classnames(
                                                'stats-table__cell-inner-wrapper',
                                                {
                                                    'stats-table__cell-inner-wrapper--empty':
                                                        !value
                                                },
                                                {
                                                    'stats-table__cell-inner-wrapper--bye':
                                                        value === 'B'
                                                }
                                            )}
                                        >
                                            {children}
                                        </div>
                                    )}
                                >
                                    {primaryTooltipComponent &&
                                    value !== 'B' ? (
                                        <TooltipButton
                                            id={getCellId(row, cell)}
                                            title={getCellTitle(row, cell)}
                                            hideArrow
                                            noPadding
                                            externalTooltip
                                            showOnHover={false}
                                            direction="below"
                                            primaryTooltipContent={primaryTooltipComponent(
                                                row,
                                                cell,
                                                benchmark
                                            )}
                                            classNames={{
                                                component:
                                                    'stats-table__cell-button-wrapper',
                                                button: 'stats-table__cell-button'
                                            }}
                                        >
                                            {value}
                                        </TooltipButton>
                                    ) : (
                                        <>{value}</>
                                    )}
                                </ConditionalWrapper>
                            </td>
                        );
                    })}
                </tr>
            );
        })}
    </tbody>
);

StatsTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    getBenchmark: PropTypes.func,
    primaryTooltipComponent: PropTypes.func,
    getCellId: PropTypes.func,
    getCellTitle: PropTypes.func,
    customCells: PropTypes.object,
    skipCells: PropTypes.array,
    valueWrapper: PropTypes.bool,
    getTrProps: PropTypes.func
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
