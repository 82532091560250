import reduxQuerySync from 'redux-query-sync';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import {
    UPDATE_NAV_ITEM,
    UPDATE_FILTER_ITEM
} from 'store/modules/competition-nav/actions';

export const configureReduxQuerySync = (store) => {
    return reduxQuerySync({
        store,
        params: {
            Competition: {
                selector: (state) =>
                    navSelector(state).competition.id?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'competition',
                        value: { id }
                    });
                },
                stringToValue: (competition) => parseInt(competition) || null
            },
            Season: {
                selector: (state) => navSelector(state).season.id?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id }
                    });
                },
                stringToValue: (season) => parseInt(season) || null
            },
            Round: {
                selector: (state) => navSelector(state).round.id?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: { id }
                    });
                },
                stringToValue: (round) => parseInt(round) || null
            },
            Teams: {
                selector: (state) => filtersSelector(state).teams?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_FILTER_ITEM.request({
                        filterItem: 'teams',
                        value: id
                    });
                },
                stringToValue: (teams) => {
                    const values = teams !== '' ? teams.split(',') : [];
                    return values.length
                        ? [...teams.split(',').map((item) => parseInt(item))]
                        : '';
                },
                defaultValue: ''
            },
            Venues: {
                selector: (state) => filtersSelector(state).venues?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_FILTER_ITEM.request({
                        filterItem: 'venues',
                        value: id
                    });
                },
                stringToValue: (venues) => {
                    const values = venues !== '' ? venues.split(',') : [];
                    return values.length
                        ? [...venues.split(',').map((item) => parseInt(item))]
                        : null;
                },
                defaultValue: ''
            },
            Cities: {
                selector: (state) => filtersSelector(state).cities?.toString(),
                action: (id) => {
                    if (!id) {
                        return { type: '' };
                    }

                    return UPDATE_FILTER_ITEM.request({
                        filterItem: 'cities',
                        value: id
                    });
                },
                stringToValue: (cities) => {
                    const values = cities !== '' ? cities.split(',') : [];
                    return values.length ? [...cities.split(',')] : null;
                },
                defaultValue: ''
            },
            HomeAway: {
                selector: (state) => navSelector(state).homeAway?.toString(),
                action: (value) => {
                    if (!value) {
                        return { type: '' };
                    }

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'homeAway',
                        value: value
                    });
                },
                defaultValue: 'all'
            },
            OtherMatches: {
                selector: (state) => navSelector(state).otherMatches?.isActive,
                action: (value) => {
                    if (!value) {
                        return { type: '' };
                    }

                    let isTrue = value === 'true';

                    return UPDATE_NAV_ITEM.request({
                        navItem: 'otherMatches',
                        value: {
                            isActive: isTrue
                        }
                    });
                },
                defaultValue: false
            }
        },
        // Initially set the store's state to the current location.
        initialTruth: 'location'
    });
};
