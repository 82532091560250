import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'common/react/components/Loader';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getLeadingScore } from 'common/react/utils/get-leading-score';

import { useGetMatchesQuery } from 'store/modules/api/afl-api';

const renderResultLabel = (winningTeam, teamId) => {
    if (winningTeam?.draw) {
        return (
            <span className="ladder-tooltip__result-label ladder-tooltip__result-label--draw">
                {getTranslation(`label.ladder.drawn`)}
            </span>
        );
    }

    if (winningTeam?.team?.id === teamId) {
        return (
            <span className="ladder-tooltip__result-label ladder-tooltip__result-label--win">
                {getTranslation(`label.ladder.won`)}
            </span>
        );
    }

    if (teamId && winningTeam?.team?.id && winningTeam?.team?.id !== teamId) {
        return (
            <span className="ladder-tooltip__result-label ladder-tooltip__result-label--lose">
                {getTranslation(`label.ladder.lost`)}
            </span>
        );
    }

    return (
        <span className="ladder-tooltip__result-label ladder-tooltip__result-label--no-data">
            {getTranslation(`label.fixtureBar.versus`)}
        </span>
    );
};

export const TooltipContent = ({ cell, formStringIndex }) => {
    // Use compseason, teamID and the last 5 rounds to hit the match endpoint.

    const teamId = cell?.row?.original?.club?.id; // teamID
    const competitionId = cell?.row?.original?.competitionId;
    const compSeasonId = cell?.row?.original?.compSeasonId;
    const roundNumber = cell?.row?.original?.roundNumber;
    const numberOfRounds = 5;

    const rounds = [...new Array(roundNumber)].map((val, index) => index + 1); // reference to all the rounds that team has played up until selected round
    rounds.unshift(0); // account for opening round

    const { data: matches = [] } = useGetMatchesQuery({
        competitionId: competitionId,
        compSeasonId: compSeasonId,
        roundNumber: rounds,
        teamId: teamId,
        status: 'C',
        sort: 'desc',
        pageSize: numberOfRounds // this ensures we only ever get 5 matches in the response
    });

    // Make a copy of the matches array so we can modify
    const matchesForSort = [...matches];

    // Start with the 'oldest' match as form icons appear left to right
    const matchesReversed = matchesForSort?.reverse();

    // match the form icon to the correct match before render
    const match = matchesReversed?.[formStringIndex];

    let result = false;

    const home = match?.home || false;
    const away = match?.away || false;
    const homeScore = match?.home?.score?.totalScore || false;
    const awayScore = match?.away?.score?.totalScore || false;

    if (home && away && homeScore && awayScore) {
        result = getLeadingScore(home, away, homeScore, awayScore);
    }

    return (
        <div className="ladder-tooltip">
            {!match ? (
                <div className="ladder-tooltip__loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="ladder-tooltip">
                        <div className="ladder-tooltip__match">
                            <span className="ladder-tooltip__team ladder-tooltip__team--home">
                                {
                                    <TeamIcon
                                        providerId={
                                            match?.home?.team?.providerId
                                        }
                                        className="ladder-tooltip__team-icon"
                                    />
                                }
                            </span>
                            <span className="ladder-tooltip__score ladder-tooltip__score--home">
                                {match?.home?.score?.totalScore}
                            </span>

                            <span className="ladder-tooltip__result">
                                {renderResultLabel(result, teamId)}
                            </span>

                            <span className="ladder-tooltip__score ladder-tooltip__score--away">
                                {match?.away?.score?.totalScore}
                            </span>
                            <span className="ladder-tooltip__team ladder-tooltip__team--away">
                                {
                                    <TeamIcon
                                        providerId={
                                            match?.away?.team?.providerId
                                        }
                                        className="ladder-tooltip__team-icon"
                                    />
                                }
                            </span>
                        </div>

                        <div className="ladder-tooltip__info-block">
                            <span className="ladder-tooltip__round">
                                {match?.round?.name}
                            </span>
                            <span className="ladder-tooltip__dot">•</span>
                            <span className="ladder-tooltip__date">
                                {luxon.DateTime.fromISO(match?.utcStartTime, {
                                    zone: 'utc'
                                })
                                    .toLocal()
                                    .toFormat('dd MMM')}
                            </span>
                            <span className="ladder-tooltip__dot">•</span>
                            <span className="ladder-tooltip__round">
                                {match?.venue?.abbreviation}
                            </span>
                        </div>

                        <a
                            href={PULSE.app.common.url.getMatchURL(match.id)}
                            className="ladder-tooltip__link"
                        >
                            <span className="ladder-tooltip__football-icon">
                                <SvgIcon icon="football" className="icon" />
                            </span>
                            <span className="ladder-tooltip__link-label">
                                {getTranslation(`label.fixtures.match-center`)}
                            </span>
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};

TooltipContent.propTypes = {
    cell: PropTypes.object,
    formStringIndex: PropTypes.string
};
