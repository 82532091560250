import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { aflApi } from './afl-api';
import { contentApi } from './content-api';
import { cfsApi } from './cfs-api';
import { cfsCommentaryApi } from './cfsCommentary-api';
import { aflmContentApi } from './aflm-content-api';

// RTK Query update: Add required exports
if (window.PULSE.app.redux) {
    window.PULSE.app.redux.configureStore = configureStore;
    window.PULSE.app.redux.createSagaMiddleware = createSagaMiddleware;
    window.PULSE.app.redux.createListenerMiddleware = createListenerMiddleware;
    window.PULSE.app.redux.aflApi = aflApi;
    window.PULSE.app.redux.cfsApi = cfsApi;
    window.PULSE.app.redux.contentApi = contentApi;
    window.PULSE.app.redux.cfsCommentaryApi = cfsCommentaryApi;
    window.PULSE.app.redux.aflmContentApi = aflmContentApi;
} else {
    window.PULSE.app.redux = {
        reducers: {},
        sagas: {},
        configureStore: configureStore,
        createSagaMiddleware: createSagaMiddleware,
        createListenerMiddleware: createListenerMiddleware,
        aflApi: aflApi,
        cfsApi: cfsApi,
        contentApi: contentApi,
        cfsCommentaryApi: cfsCommentaryApi,
        aflmContentApi: aflmContentApi
    };
}
