import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';

import {
    SeasonNavItem,
    CompNavItem,
    HomeAwayNavItem,
    OtherMatchesToggle,
    ResponsiveHomeAwayNavItem,
    CalendarButton
} from 'common/react/components/competition-nav/nav-items';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { ResponsiveSeasonNavItem } from 'common/react/components/competition-nav/nav-items';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';

export const FixturesNav = ({
    competitionId,
    seasonId,
    competitionProviderId,
    seasonPid,
    ecalId
}) => {
    const windowSize = useWindowSize();

    const isResponsive = windowSize.width < PULSE.app.measurements.desktop;
    const isNotWide = windowSize.width < PULSE.app.measurements.wide;

    const initialValues = {
        competitionId,
        seasonId,
        competitionProviderId,
        seasonPid
    };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);

    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const currentFilters = useGetCurrentCompFilters();

    return (
        <div className="competition-nav">
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CompNavItem competitions={competitions} />

                        {!isResponsive && <SeasonNavItem seasons={seasons} />}
                        {!isResponsive && (
                            <HomeAwayNavItem classes={'u-margin-right-auto'} />
                        )}
                        {!isNotWide && (
                            <OtherMatchesToggle classes={'no-border'} />
                        )}

                        {ecalId && (
                            <CalendarButton
                                classes="stats-leaders-nav__button no-border"
                                ecalId={ecalId}
                            />
                        )}
                    </StatsSection.NavItems>

                    {isResponsive &&
                    currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems hideReset={true}>
                            <ResponsiveSeasonNavItem seasons={seasons} />
                            <ResponsiveHomeAwayNavItem />
                        </StatsSection.NavFilterItems>
                    ) : null}
                </StatsSection.Nav>
            </StatsSection.Main>

            {isNotWide && (
                <div className="wrapper">
                    <OtherMatchesToggle
                        style={{ justifyContent: 'flex-end' }}
                    />
                </div>
            )}
        </div>
    );
};

FixturesNav.propTypes = {
    competitionId: PropTypes.string,
    competitionProviderId: PropTypes.string,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.string,
    ecalId: PropTypes.string.isRequired
};
