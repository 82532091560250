/* eslint-disable no-magic-numbers */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { SvgIcon } from '../SvgIcon';
import { positionExternalTooltipElements } from './utils/position-external-tooltip-elements';
import { positionTooltipElements } from './utils/position-tooltip-elements';
import { useWindowSize } from '../../hooks/use-window-resize';

export const Tooltip = ({
    id,
    hideArrow,
    noPadding,
    theme,
    direction,
    tooltipTarget,
    externalTooltipWrapper,
    primaryTooltipContent,
    secondaryTooltipContent,
    closeTooltip,
    tooltipPadding
}) => {
    const wrapper = useRef();
    const arrow = useRef();
    const primary = useRef();

    const [isSecondaryOpen, setIsSecondaryOpen] = useState(false);

    const { width: windowWidth } = useWindowSize();

    const layoutEffectCallback = () => {
        if (tooltipTarget && externalTooltipWrapper) {
            // Position the external tooltip.
            positionExternalTooltipElements({
                target: tooltipTarget,
                wrapper: wrapper.current,
                primary: primary.current,
                arrow: arrow.current,
                direction,
                tooltipPadding: tooltipPadding
            });
        } else {
            // Adjust the natural horizontal position of the tooltip (if needed)
            // to ensure it appears fully within the viewport.
            positionTooltipElements(wrapper.current);
        }
    };

    useLayoutEffect(layoutEffectCallback, [
        tooltipTarget,
        primaryTooltipContent,
        secondaryTooltipContent,
        isSecondaryOpen
    ]);

    // We need to debounce the tooltip positioning when the screen width changes
    // top make sure the page layout has settled before proceeding with the
    // calculations which are based off the elements on the page.
    useLayoutEffect(_.debounce(layoutEffectCallback, 250), [windowWidth]);

    const documentClickEventHandler = ({ target }) => {
        if (
            wrapper?.current?.contains(target) ||
            externalTooltipWrapper?.contains(target)
        ) {
            return;
        }

        closeTooltip();
    };

    useEffect(() => {
        /**
         * The setTimeOut is to delay setting the event listener so it doesn't
         * get batched and executed immediately on first render in react 18.
         *
         * @see https://github.com/facebook/react/issues/24657
         */
        setTimeout(() => {
            document.addEventListener('click', documentClickEventHandler);
        });

        return () => {
            document.removeEventListener('click', documentClickEventHandler);
        };
    }, []);

    const tooltipComponent = (
        <div
            ref={wrapper}
            role="tooltip"
            id={id}
            className={classNames(
                `tooltip-button__wrapper  tooltip-button__wrapper--theme-${theme} tooltip-button__wrapper--direction-${direction}`,
                {
                    'tooltip-button__wrapper--outside': externalTooltipWrapper,
                    'tooltip-button__wrapper--hide-arrow': hideArrow
                }
            )}
        >
            {/* The secondary tooltip. */}
            <div
                className={classNames(
                    'tooltip-button__secondary-tooltip-wrapper',
                    {
                        'tooltip-button__secondary-tooltip-wrapper--hidden':
                            !isSecondaryOpen,
                        'tooltip-button__secondary-tooltip-wrapper--no-padding':
                            noPadding
                    }
                )}
            >
                {secondaryTooltipContent}
            </div>

            {/* The primary tooltip arrow. */}
            <div ref={arrow} className="tooltip-button__arrow" />

            {/* The primary tooltip. */}
            <div
                ref={primary}
                className={classNames(
                    'tooltip-button__primary-tooltip-wrapper',
                    {
                        'tooltip-button__primary-tooltip-wrapper--no-padding':
                            noPadding
                    }
                )}
            >
                {/* The tooltip content. */}
                {primaryTooltipContent}

                {/* The button to open the secondary tooltip. */}
                {secondaryTooltipContent && (
                    <button
                        aria-label="More info"
                        className="tooltip-button__secondary-tooltip-button"
                        onClick={() => setIsSecondaryOpen((val) => !val)}
                    >
                        <SvgIcon icon="info-circle" />
                    </button>
                )}
            </div>
        </div>
    );

    if (externalTooltipWrapper) {
        return createPortal(tooltipComponent, externalTooltipWrapper);
    }

    return tooltipComponent;
};

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    hideArrow: PropTypes.bool,
    noPadding: PropTypes.bool,
    primaryTooltipContent: PropTypes.any.isRequired,
    secondaryTooltipContent: PropTypes.any,
    tooltipTarget: PropTypes.any.isRequired,
    externalTooltipWrapper: PropTypes.any,
    theme: PropTypes.oneOf(['light', 'dark']),
    direction: PropTypes.oneOf(['above', 'below']),
    tooltipPadding: PropTypes.number,
    closeTooltip: PropTypes.func.isRequired
};
