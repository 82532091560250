import { all, takeEvery } from 'redux-saga/effects';
import { FETCH_LEADERBOARD_DATA } from '../actions';
import { fetchLeaderboardData } from './fetch-leaderboard-data';

/**
 *
 */
export function* leaderboardRootSaga() {
    yield all([
        takeEvery(FETCH_LEADERBOARD_DATA.REQUEST, fetchLeaderboardData)
    ]);
}
