import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';

export const RoundButton = ({ round, currentRoundNumber, onClick }) => {
    const nav = useSelector(navSelector);

    const filters = useSelector(filtersSelector);

    const disableRounds =
        filters?.cities.length > 0 ||
        filters?.teams.length > 0 ||
        filters?.venues.length > 0;

    const isActive = Number(nav.round.id) === round.id && !disableRounds;

    let roundNumber;

    if (round.abbreviation.includes('Rd')) {
        roundNumber = round.roundNumber;
    } else {
        roundNumber = round.abbreviation;
    }

    return (
        <li
            className={`competition-nav__round-list-item ${
                isActive ? 'is-active' : ''
            } ${
                round.roundNumber === currentRoundNumber
                    ? 'competition-nav__round-list-item--current-round'
                    : ''
            } ${
                round.roundNumber > currentRoundNumber
                    ? 'competition-nav__round-list-item--future-round'
                    : ''
            }`}
        >
            <button
                className="competition-nav__round-list-button"
                onClick={(event) => onClick(event, round, disableRounds)}
            >
                {roundNumber}
            </button>
        </li>
    );
};

RoundButton.propTypes = {
    round: PropTypes.object,
    currentRoundNumber: PropTypes.number,
    onClick: PropTypes.func
};
