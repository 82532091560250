/**
 * @typedef {object} Setting
 * @property {string} key The key to use to access the setting
 * @property {string} type The type of setting
 * @property {string} label The label to display for the setting
 * @property {string|boolean} defaultValue The default value for the setting
 * @property {boolean} syncToSalesForce Whether to sync the setting to salesforce
 */

/**
 * @typedef {object} SiteSettingConfig
 * @property {Setting} region The config for the region setting
 */

/**
 * @constant {SiteSettingConfig} SITE_SETTINGS_CONFIG The config for the settings system
 */
export const SITE_SETTINGS_CONFIG = {
    region: {
        key: 'region',
        type: 'select',
        labelKey: 'label.siteSettings.region',
        defaultValue: {
            timezone: moment.tz.guess(true),
            name: '',
            id: '',
            location: '',
            region: ''
        },
        syncToSalesForce: false
    }
};

/**
 * Attach the config to the window object so we can access outside of the
 * webpack/module system
 */
PULSE.app.common.SITE_SETTINGS_CONFIG = SITE_SETTINGS_CONFIG;
