import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SKELETON_COUNT = 1;

export const TASkeleton = () => {
    return (
        <Skeleton
            className="team-announcements-field__skeleton"
            count={SKELETON_COUNT}
        />
    );
};
