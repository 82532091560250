import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';

import { QualifiersBanner } from '../QualifiersBanner';

import { StatsTableRowHeader } from '../../../../statspro/js/components/common/stats-table/StatsTableRowHeader';

export const BodyCellPosition = ({ cell, cellProps }) => {
    const position = cell.value;
    const positionChange = cell?.row?.values?.positionChange || 'none';

    const isActiveClub = cell?.row?.original?.isActiveClub;

    const svgName = `pos-change-${
        positionChange.toLowerCase() + (isActiveClub ? '-white' : '')
    }`;

    const rowIndex = cell?.row?.index;

    const isConference = cell?.row?.original?.isConference;
    const qualiferRow = cell?.row?.original?.qualiferRow;

    const teamId = cell?.row?.values?.club?.providerId || false;
    const teamAbbr = cell?.row?.values?.club?.abbreviation || '';

    const isConferenceRow =
        rowIndex === 1 && isConference ? 'is-conference-row' : '';

    return (
        <StatsTableRowHeader
            cellProps={cellProps}
            className={`stats-table__row-header-cell stats-table__cell--position ${isConferenceRow}`}
        >
            <div className="stats-table__position-club-wrapper">
                <div className="stats-table__position">
                    <span className="stats-table__position-cell-label">
                        {position}
                    </span>
                    <SvgIcon icon={svgName} className="icon" />
                </div>

                <div className="stats-table__club">
                    {teamId ? (
                        <TeamIcon
                            providerId={teamId}
                            theme={PULSE.app.common.team.getTeamLogoTheme(
                                teamAbbr,
                                'b',
                                isActiveClub ? 'light' : ''
                            )}
                        />
                    ) : null}
                </div>
            </div>

            {rowIndex === qualiferRow && (
                <QualifiersBanner qualifierRow={qualiferRow} />
            )}
        </StatsTableRowHeader>
    );
};

BodyCellPosition.propTypes = {
    cell: PropTypes.object,
    cellProps: PropTypes.object
};
