import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import classNames from 'classnames';

import { SvgIcon } from '../../SvgIcon';
import { CompIcon } from '../../CompIcon';
import { DrawerBottom } from '../../drawer';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param {any[]} props.items - Items.
 * @param {Function} [props.itemToString] - Item to string.
 * @param {any} [props.initialSelectedItem] - Initial selected item.
 * @param {any} [props.activeItem] - Active item from state.
 * @param {Function} [props.onSelectedItemChange] - On change callback.
 * @returns {JSX.Element} Component.
 */
export const SelectMobile = (props) => {
    const {
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getLabelProps
    } = useSelect({
        items: props.items,
        initialSelectedItem: props.initialSelectedItem,
        selectedItem: props.activeItem,
        // @ts-ignore
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            /**
             * setTimeOut is required due to an issue with downshift and react
             * 18. Once the github issue is resovled, it should be able to be
             * removed.
             *
             * @see https://github.com/downshift-js/downshift/issues/1511#issuecomment-1730429456
             * @see https://github.com/downshift-js/downshift/issues/1534
             * @see https://github.com/facebook/react/issues/27666
             */
            setTimeout(() => {
                setIsOpen(false);
                if (typeof props.onSelectedItemChange === 'function') {
                    props.onSelectedItemChange({
                        selectedItem: newSelectedItem
                    });
                }
            });
        }
    });

    const currentRound = props?.currentRoundIndex
        ? props.currentRoundIndex
        : false;

    const isDisabled = props?.isDisabled;

    /**
     * @param {any} item - Item.
     * @returns {string} Text.
     */
    const getItemText = (item) =>
        props.itemToString ? props.itemToString(item) : item;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="select">
                <button
                    type="button"
                    {...getToggleButtonProps({
                        id: `${PULSE.app.common.idifyString(
                            props.label
                        )}-mobile-dropdown-button`,
                        'aria-labelledby': `${PULSE.app.common.idifyString(
                            props.label
                        )}-mobile-dropdown-label`
                    })}
                    className="select__display"
                    onClick={() => setIsOpen(true)}
                    disabled={isDisabled}
                >
                    {props.showCompLogo && selectedItem?.code && (
                        <span
                            className={`competition-nav__comp-icon competition-nav__comp-icon--${selectedItem.code.toLowerCase()}`}
                        >
                            <CompIcon
                                code={selectedItem.code}
                                className="icon"
                            />
                        </span>
                    )}
                    <span>
                        {props.label ? (
                            <label
                                {...getLabelProps({
                                    id: `${PULSE.app.common.idifyString(
                                        props.label
                                    )}-mobile-dropdown-label`
                                })}
                                className="select__label"
                            >
                                {props.label}
                            </label>
                        ) : null}
                        <span className="select__current">
                            <span className="select__current-text">
                                {getItemText(selectedItem) || ''}
                            </span>
                        </span>
                    </span>

                    <SvgIcon className="select__chevron" icon="chevron-down" />
                </button>
            </div>

            <DrawerBottom
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                heading={props.label}
            >
                <div className="select select--mobile select--open">
                    {/*
                        An invisible label for accessibility purposes.
                    */}
                    <label className="u-hide" {...getLabelProps()}>
                        {props.label}
                    </label>

                    {/*
                        An invisible dummy toggle button just so we're calling
                        getToggleButtonProps to ensure rc-drawer doesn't crash.
                    */}
                    <button
                        className="u-hide"
                        {...getToggleButtonProps()}
                    ></button>
                    <div className="select__options-wrapper">
                        <ul
                            {...getMenuProps(
                                {
                                    'aria-labelledby': `${PULSE.app.common.idifyString(
                                        props.label
                                    )}-mobile-dropdown-label`
                                },
                                { suppressRefError: true }
                            )}
                            className={classNames('select__options-list', {
                                'u-hide': !isOpen
                            })}
                        >
                            {props.items.map((item, index) => {
                                const itemText = getItemText(item);
                                const isSelected =
                                    getItemText(selectedItem) === itemText;

                                return (
                                    <li
                                        key={`${itemText}${index}`}
                                        {...getItemProps({ item, index })}
                                        className={classNames(
                                            'select__option',
                                            {
                                                'select__option--selected':
                                                    isSelected,
                                                'select__option--current':
                                                    currentRound === index,
                                                'select__option--highlighted':
                                                    highlightedIndex === index
                                            }
                                        )}
                                    >
                                        <span>{itemText}</span>

                                        {isSelected && (
                                            <SvgIcon
                                                icon="checkmark"
                                                className="select__option-button-icon"
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </DrawerBottom>
        </>
    );
};

SelectMobile.propTypes = {
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    initialSelectedItem: PropTypes.any,
    activeItem: PropTypes.any,
    onSelectedItemChange: PropTypes.func,
    currentRoundIndex: PropTypes.number,
    showCompLogo: PropTypes.bool,
    isDisabled: PropTypes.bool
};
