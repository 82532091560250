export const getEventTagProperties = (tag) => {
    return EVENT_TAG_PROPERTIES.find((item) => item.name === tag);
};

const EVENT_TAG_PROPERTIES = [
    {
        name: 'Appearances',
        icon: 'appearances'
    },
    {
        name: 'Matches',
        icon: 'matches'
    },
    {
        name: 'Music',
        icon: 'live-music'
    },
    {
        name: 'Live Music',
        icon: 'live-music'
    },
    {
        name: 'Food',
        icon: 'food'
    },
    {
        name: 'Drinks',
        icon: 'drinks'
    },
    {
        name: 'Seating',
        icon: 'seating'
    },
    {
        name: 'Big Screens',
        icon: 'big-screens'
    },
    {
        name: 'Toilets',
        icon: 'toilets'
    },
    {
        name: 'Activations',
        icon: 'activations'
    },
    {
        name: 'Misc',
        icon: 'misc'
    },
    {
        name: 'Transport',
        icon: 'transport'
    },
    {
        name: 'First Aid',
        icon: 'first-aid'
    },
    {
        name: 'Merchandise',
        icon: 'merchandise'
    },
    {
        name: 'Other',
        icon: 'other'
    },
    {
        name: 'Clinics',
        icon: 'clinics'
    },
    {
        name: 'Free Entry',
        icon: 'free-entry'
    },
    {
        name: 'Coffee',
        icon: 'coffee'
    },
    {
        name: 'Entry',
        icon: 'entry'
    },
    {
        name: 'Exit',
        icon: 'exit'
    },
    {
        name: 'Hospitality',
        icon: 'hospitality'
    },
    {
        name: 'Information',
        icon: 'information'
    },
    {
        name: 'Parking',
        icon: 'parking'
    },
    {
        name: 'Ticket Sales',
        icon: 'ticket-sales'
    },
    {
        name: 'Water Fountain',
        icon: 'water-fountain'
    },
    {
        name: 'Events',
        icon: 'events'
    }
];
