import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

export const RoundVotes = ({ data, rounds }) => {
    const maxRounds =
        rounds?.filter(PULSE.app.common.match.isNonFinalRound).length ?? 0;

    return (
        <div className="brownlow-round-votes">
            {data.roundByRoundVotes &&
                [...Array(Number(maxRounds))].map((round, i) => {
                    const roundData = data.roundByRoundVotes.find(
                        (item) => item.roundNumber === i + 1
                    );
                    const votes = roundData?.votes;
                    const bye = rounds
                        ?.find((item) => item.roundNumber === i + 1)
                        ?.byes.find(
                            (item) => item.providerId === data?.team.teamId
                        );

                    return (
                        <div
                            key={i}
                            className={classNames(
                                'brownlow-round-votes__round-container',
                                {
                                    'brownlow-round-votes__round-container--bye':
                                        bye,
                                    'brownlow-round-votes__round-container--ineligible':
                                        !bye && !data.eligible && votes,
                                    'brownlow-round-votes__round-container--empty':
                                        !bye && !votes
                                }
                            )}
                        >
                            {bye ? 'B' : votes ?? ''}
                        </div>
                    );
                })}
        </div>
    );
};

RoundVotes.propTypes = {
    data: PropTypes.any.isRequired,
    rounds: PropTypes.array.isRequired
};
