import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SKELETON_COUNT = 3;

export const CardSkeleton = () => {
    return (
        <Skeleton
            baseColor="var(--color-neutral-white)"
            highlightColor="var(--color-neutral-200)"
            className="events-listing__skeleton"
            count={SKELETON_COUNT}
        />
    );
};
