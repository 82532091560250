/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TooltipButton } from 'common/react/components/tooltip-button';

export const StatsTableHead = ({
    headerGroup,
    skipColumns,
    primaryTooltipComponent,
    secondaryTooltipComponent,
    doNotRenderTooltipForRows,
    customCells
}) => {
    // @ts-ignore
    const { render } = PULSE.app.templating;
    const isTouchScreen = PULSE.app.common.deviceHelper.isTouchScreen();
    const headers = headerGroup.headers.filter((column) => {
        if (!skipColumns) {
            return true;
        }
        return !skipColumns.includes(column.id);
    });

    return (
        <thead>
            <tr
                {...headerGroup.getHeaderGroupProps()}
                className="stats-table__header-row"
            >
                {headers.map((column) => {
                    const cellContent = column?.render('Header');
                    const { onClick, ...headerProps } = column.getHeaderProps(
                        column?.getSortByToggleProps()
                    );

                    if (customCells && customCells[column.id]) {
                        return customCells[column.id](column);
                    }

                    const Content = () => (
                        <>
                            {cellContent}
                            {column.isSorted && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: render(
                                            {
                                                cssClass: classnames(
                                                    'stats-table__sort-icon',
                                                    {
                                                        'stats-table__sort-icon--flipped':
                                                            !column.isSortedDesc
                                                    }
                                                ),
                                                name: 'dropdown'
                                            },
                                            'common.svg-icon'
                                        )
                                    }}
                                ></span>
                            )}
                        </>
                    );

                    if (
                        !primaryTooltipComponent ||
                        isTouchScreen ||
                        doNotRenderTooltipForRows?.includes(column.id)
                    ) {
                        return (
                            <th
                                {...headerProps}
                                className={classnames(
                                    'stats-table__header-cell',
                                    {
                                        'stats-table__header-cell--sorted':
                                            column.isSorted
                                    }
                                )}
                                scope="col"
                            >
                                <button
                                    className="stats-table__header-cell-button"
                                    onClick={onClick}
                                    disabled={!column.canSort}
                                >
                                    <Content />
                                </button>
                            </th>
                        );
                    }

                    return (
                        <th
                            {...headerProps}
                            className={classnames('stats-table__header-cell', {
                                'stats-table__header-cell--sorted':
                                    column.isSorted
                            })}
                            scope="col"
                        >
                            <TooltipButton
                                id={`stats-leaders-sort-${column.id}`}
                                externalTooltip
                                suppressFocusStyles
                                primaryTooltipContent={
                                    primaryTooltipComponent &&
                                    primaryTooltipComponent(column)
                                }
                                secondaryTooltipContent={
                                    secondaryTooltipComponent &&
                                    secondaryTooltipComponent(column)
                                }
                                classNames={{
                                    component:
                                        'stats-table__header-cell-button-wrapper',
                                    button: 'stats-table__header-cell-button'
                                }}
                                onClick={onClick}
                                disabled={!column.canSort}
                                title={
                                    column.canSort
                                        ? `Sort by ${cellContent} ${getNextSortDirection(
                                              column
                                          )}`
                                        : null
                                }
                                theme="dark"
                            >
                                <Content />
                            </TooltipButton>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

StatsTableHead.propTypes = {
    headerGroup: PropTypes.object.isRequired,
    skipColumns: PropTypes.array,
    primaryTooltipComponent: PropTypes.any,
    secondaryTooltipComponent: PropTypes.any,
    doNotRenderTooltipForRows: PropTypes.arrayOf(PropTypes.string),
    customCells: PropTypes.any
};

const getNextSortDirection = (column) => {
    if (!column.isSorted) {
        return 'descending';
    }

    return column.isSortedDesc ? 'ascending' : 'descending';
};
