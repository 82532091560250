import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from '../../select';
import { getTranslation } from '../../../utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';

const { TEAM_IDS } = window.PULSE.app.common.CONSTANTS;

export const CompNavItem = ({ competitions, hidePreseason }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    if (!competitions || nav.competition.id === '') {
        return '';
    }
    const PRESEASON_INDEX = 1;

    const DEFAULT_COMP_ORDER = [
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.WOMENS,
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP,
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_PREMIERSHIP,
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_WOMENS,
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.WAFL_PREMIERSHIP,
        PULSE.app.common.CONSTANTS.PROVIDER_IDS.SANFL_PREMIERSHIP
    ];

    let competitionList = '';

    !hidePreseason
        ? DEFAULT_COMP_ORDER.splice(
              PRESEASON_INDEX,
              0,
              PULSE.app.common.CONSTANTS.PROVIDER_IDS.PRE_SEASON
          )
        : '';

    // Filter the competitions list to only show the competitions above
    const getCompetitionList = (
        data,
        providerIdsToInclude = DEFAULT_COMP_ORDER
    ) => {
        /**
         * Filters the list of competitions to include only those which appear
         * in the list of competition IDs provided.
         *
         * @param { any[] } comps The list of competition data.
         * @param { string[] } compProviderIds A list of `compProviderIds` in
         *    the order we want to display them in the filter.
         * @returns { any[] } The list of competitions included in given list of
         *    `compProviderIds`.
         */
        const filterOutExcludedComps = (comps, compProviderIds) =>
            comps.filter((comp) => compProviderIds.includes(comp.providerId));

        // /**
        //  * Sorts the list of competitions into the order of the list of
        //  * competition IDs provided.
        //  *
        //  * @param { any[] } comps The list of competition data.
        //  * @param { string[] } compProviderIds A list of `compProviderIds` in
        //  *    the order we want to display them in the filter.
        //  * @returns { any[] } The list of competitions in order according to the
        //  *    given list of `compProviderIds`.
        //  */
        const sortAccordingToConfig = (comps, compProviderIds) =>
            compProviderIds.map((compProviderId) =>
                comps.find((comp) => comp.providerId === compProviderId)
            );

        const filtered = filterOutExcludedComps(data, providerIdsToInclude);
        const sorted = sortAccordingToConfig(filtered, providerIdsToInclude);
        competitionList = sorted;
    };

    getCompetitionList(competitions);

    const relevantCompetitions = !Object.keys(TEAM_IDS).length
        ? competitionList
        : competitionList.filter((item) => {
              if (typeof TEAM_IDS[item.code] !== 'undefined') {
                  return item;
              }

              return false;
          });

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={relevantCompetitions}
                itemToString={(competition) => competition.name}
                initialSelectedItem={competitions.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                showCompLogo={true}
                activeItem={relevantCompetitions.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                isReadyToRender={() =>
                    relevantCompetitions?.length && Number(nav.competition.id)
                }
                label={getTranslation('label.filters.competition')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: {
                                id: selectedItem.id,
                                providerId: selectedItem.providerId
                            }
                        })
                    );
                }}
            />
        </div>
    );
};

CompNavItem.propTypes = {
    competitions: PropTypes.array,
    hidePreseason: PropTypes.bool
};
