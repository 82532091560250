import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from './SvgIcon';
import { getTranslation } from '../utils/translations';

export const EmptyState = ({
    title,
    summary,
    cssClass,
    iconName,
    iconClass,
    titleTranslation,
    summaryTranslation
}) => {
    const translations = {
        title: getTranslation(
            titleTranslation || 'label.empty.state.noData.title'
        ),
        summary: getTranslation(
            summaryTranslation || 'label.empty.state.noData.summary'
        )
    };

    return (
        <div className={`empty-state ${cssClass || ''}`}>
            <div className="empty-state__background"></div>
            <div className="empty-state__message">
                <SvgIcon
                    icon={iconName || 'empty'}
                    className={`icon ${iconClass || 'icn-empty'}`}
                />
                <h3 className="empty-state__message-label">
                    {title || translations.title}
                </h3>
                <span className="empty-state__summary">
                    {summary || translations.summary}
                </span>
            </div>
        </div>
    );
};

EmptyState.propTypes = {
    title: PropTypes.string,
    summary: PropTypes.string,
    cssClass: PropTypes.string,
    iconName: PropTypes.string,
    iconClass: PropTypes.string,
    titleTranslation: PropTypes.string,
    summaryTranslation: PropTypes.string
};
