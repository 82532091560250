import React from 'react';
import PropTypes from 'prop-types';

export const FixturesDateHeader = ({ dateKey, label }) => {
    const labelText = label
        ? label
        : dateKey
        ? moment(dateKey, 'YYYYMMDD').format('dddd MMMM D')
        : '';

    return <h2 className="fixtures__date-header">{labelText}</h2>;
};

FixturesDateHeader.propTypes = {
    dateKey: PropTypes.string,
    label: PropTypes.string
};
