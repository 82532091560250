import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGetEventsQuery } from 'common/store/modules/api/content-api';

import { CardSkeleton } from './cards/CardSkeleton';
import { EventsListingByFilter } from './EventsListingByFilter';
import { sortByDate } from '../utils/sort-by-date';
import { getCurrentDateBounds } from '../utils/get-current-date-bounds';

const dateBounds = getCurrentDateBounds();

/**
 *
 * @param {number} eventId Which event playlist to pull events from.
 * @param {number} compseasonId The competetive season to look at for team filters.
 * @param {string} localTimezone The timezone to look at for date filtering.
 * @param {string} tags The tag that determines from which category the events are pulled from (only uses first tag).
 * @param {string} maxEvents The maximum number of events to display.
 * @returns {React.JSX.Component} The list of featured events cards.
 */
export const FtdEventsListing = ({
    eventId,
    compseasonId,
    localTimezone,
    tags,
    maxEvents
}) => {
    const wrapperRef = useRef();
    const tagToMatch = tags?.split(',')[0];

    const contentFilter = {
        rangeFilter: {
            lowerBound: {
                fieldName: 'Date & Time',
                dvalue: `${dateBounds.lowerBound}`
            },
            upperBound: {
                fieldName: 'Date & Time',
                dvalue: `${dateBounds.upperBound}`
            }
        }
    };

    const objJsonStr = JSON.stringify(contentFilter);
    const encodedString = btoa(objJsonStr);

    const filterParams = {
        contentFilter: encodedString,
        tagNames: tagToMatch,
        pageSize: maxEvents
    };

    const { limitedSortedData, isFetching, isSuccess } = useGetEventsQuery(
        {
            eventId: eventId,
            params: filterParams
        },
        {
            skip: !eventId,
            selectFromResult: ({ data, ...rest }) => {
                const sortedData = sortByDate(data);
                return {
                    ...rest,
                    limitedSortedData: sortedData.slice(0, maxEvents)
                };
            }
        }
    );

    // When we're loaded and there are no events, remove the whole element
    useEffect(() => {
        if (
            wrapperRef?.current &&
            isSuccess &&
            limitedSortedData?.length === 0
        ) {
            const parentEle = wrapperRef.current.closest(
                '.js-featured-events-parent'
            );

            if (parentEle) {
                parentEle.remove();
            }
        }
    }, [isSuccess, limitedSortedData?.length]);

    return isFetching ? (
        <div className="events-listing">
            <div className="events-listing__header"></div>
            <CardSkeleton />
        </div>
    ) : (
        <div className="events-listing__events-wrapper" ref={wrapperRef}>
            <EventsListingByFilter
                eventsArray={limitedSortedData}
                localTimezone={localTimezone}
                compseasonId={compseasonId}
            />
        </div>
    );
};

FtdEventsListing.propTypes = {
    localTimezone: PropTypes.string,
    compseasonId: PropTypes.string,
    eventId: PropTypes.string.isRequired,
    tags: PropTypes.string,
    maxEvents: PropTypes.string
};
