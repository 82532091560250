/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { PlayerImage } from './PlayerImage';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({ rows, getTableBodyProps, prepareRow }) => {
    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            customCells={{
                jumperNumber: (row, cell, cellProps) => (
                    <th
                        {...cellProps}
                        className={classNames('stats-table__row-header-cell', {
                            'stats-table__cell--sorted': cell.column.isSorted
                        })}
                    >
                        <div className="mc-player-stats-table__jumper-number-container">
                            <PlayerImage
                                photoURL={cell.value.photoURL}
                                photoName={`${cell?.value?.givenName} ${cell?.value?.surname}`}
                            />
                            <span
                                className={`mc-player-stats-table__jumper-number mc-player-stats-table__jumper-number--${cell.value.teamLocation} theme--aflc-${cell.value.teamAbbr}`}
                            >
                                {cell.value.jumperNumber}
                            </span>
                        </div>
                    </th>
                ),
                player: (row, cell, cellProps) => (
                    <td {...cellProps} className="stats-table__cell">
                        <div className="mc-player-stats-table__player">
                            {cell.value.givenName}{' '}
                            <strong>{cell.value.surname}</strong>
                        </div>
                    </td>
                ),
                empty: (row, cell, cellProps) => (
                    <td
                        {...cellProps}
                        className="stats-table__cell stats-table__cell--empty js-player-stats-empty-cell"
                    ></td>
                )
            }}
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired
};
